import React, { useState, useRef, useEffect } from "react";

//Images import
import WhatsAppImage from "../../assets/Home/whatsapp.png";
import EllipsesImages from "../../assets/Home/ellipses.svg";
import BannerImage from "../../assets/Home/banner.png";
import StarImage from "../../assets/Home/star.png";
import ChessMonitorImage from "../../assets/Home/chessMonitor.png";
import MainSystemPrint from "../../assets/Home/mainSystemPrint.png";
import AppPrint from "../../assets/Home/appPrint.png";
import FaceIcon from "../../assets/Home/faceIcon.svg";
import DocIcon from "../../assets/Home/docIcon.svg";
import PieceIcon from "../../assets/Home/pieceIcon.svg";
import CameraIcon from "../../assets/Home/cameraIcon.svg";
import DashedWave from "../../assets/Home/dashedWave.svg";
import Arrow1 from "../../assets/Home/arrow1.svg";
import Arrow2 from "../../assets/Home/arrow2.svg";
import Attestant1 from "../../assets/Home/rafaelaAbreu.jpg";
import Attestant2 from "../../assets/Home/priscilaAguiar.jpg";
import QuoteLeft from "../../assets/Home/quoteLeft.svg";
import QuoteRight from "../../assets/Home/quoteRight.svg";
import LGPDImage from "../../assets/Home/lgpd/lgpd.svg";
import HIPAA from "../../assets/Home/lgpd/hipaa.svg";
import GDPR from "../../assets/Home/lgpd/gdpr.svg";
import ISO from "../../assets/Home/lgpd/iso.svg";

import ModalForm from "../../components/ModalForm";

import ReactGA from "react-ga";

//Styled-components imports
import {
  Container,
  BannerRow,
  BannerTitleContainer,
  TestButton,
  WhatsAppButton,
  WhatWeAreRow,
  WhatWeAreDiv1,
  WhatWeAreDiv2,
  WhatWeAreDiv3,
  RowCentered,
  RowBetween,
  WhatWeAreList,
  Col,
  FunctionalitiesGrid,
  FunctionalitiesCircle,
  FunctionalitiesCol,
  HowItWorksCircle,
  HowItWorksDiv,
  ArrowsDiv,
  SideTestify,
  TestifiesContainer,
  ColCentered,
  MiddleTestify,
  HowItWorksBackground,
  LGPDInnerContainer,
  LGPDRow,
  MiddleTestifyContent,
  LGPDOutterContainer,
} from "./styles";

//Components imports
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { landingPageEvent } from "../../facebookPixel";

export default function Main(props) {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const url = props.location.pathname.split("/");
  const linkSite = url[1];

  const [activeTab, setActiveTab] = useState("therapist");
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const functionalitiesRef = useRef();

  function toggleMenu() {
    console.log(
      document
        .getElementById("buttons-container")
        .classList.contains("r-nav-d-block")
    );

    if (
      document
        .getElementById("buttons-container")
        .className.includes("r-nav-d-block")
    ) {
      document
        .getElementById("buttons-container")
        .classList.remove("r-nav-d-block");
    } else {
      document
        .getElementById("buttons-container")
        .classList.add("r-nav-d-block");
    }
  }

  // useEffect(() => {
  //   const onScroll = (e) => {
  //     if (window.pageYOffset >= 90) {
  //       document.getElementById("main-nav").classList.add("navbar-sticky");
  //     } else {
  //       document.getElementById("main-nav").classList.remove("navbar-sticky");
  //     }
  //   };
  //   window.addEventListener("scroll", onScroll);

  //   if (linkSite === "funcionalidades") {
  //     functionalitiesRef.current.scrollIntoView();
  //   }

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  useEffect(()=>{
    landingPageEvent();

  },[])

  return (
    <>
      <NavBar activePage={0} handleModal={handleModal} />
      <Container>
        <img
          src={EllipsesImages}
          alt={EllipsesImages}
          style={{
            width: "100%",
            position: "absolute",
            top: "-100px",
            zIndex: "-20",
          }}
        />
        <BannerRow>
          <BannerTitleContainer>
            <h1>
              A sua plataforma com Jogos Digitais e Recursos Terapêuticos
              interativos nos atendimentos on-line
            </h1>
            <h2>
              Aprimore suas sessões com novas ferramentas utilizadas em tempo
              real e otimize a gestão do seu consultório
            </h2>
            <TestButton onClick={handleModal}>
              <img src={StarImage} alt={StarImage} />
              <h1>Teste gratuitamente</h1>
            </TestButton>
          </BannerTitleContainer>
          <img src={BannerImage} alt={BannerImage} />
        </BannerRow>

        <WhatWeAreRow>
          <WhatWeAreDiv1>
            <div>
              <h1>Recursos e Jogos terapêuticos</h1>
              <h2>
                Inove na eficácia dos seus atendimentos on-line através da
                videochamada com recursos terapêuticos e deixe sua consulta
                on-line mais dinâmica para crianças e adolescentes através dos
                jogos digitais.
              </h2>
            </div>
            <img src={ChessMonitorImage} alt={ChessMonitorImage} />
          </WhatWeAreDiv1>

          <WhatWeAreDiv2>
            <h1>O que somos?</h1>
            <h2>
              A Terapia Interativa é uma plataforma para atendimentos on-line e
              gestão completa do consultório. Oferecemos diversos recursos
              terapêuticos e jogos digitais aumentando as possibilidades de
              atuação da clínica on-line. O objetivo é tornar a rotina do
              profissional mais prática e funcional, adotando os mais rigorosos
              protocolos de segurança disponíveis atualmente.
            </h2>
            <img src={MainSystemPrint} alt={MainSystemPrint} />
          </WhatWeAreDiv2>
          <WhatWeAreDiv3>
            <div>
              <h1>App para pacientes</h1>
              <h2>
                Tenha mais engajamento facilitando a vida do seu cliente. Com o
                app ele poderá ver as sessões, confirmar ou reagendar sessões, e muito mais. Além disso, você poderá acompanhar tudo
                pela plataforma.
              </h2>
            </div>
            <RowBetween>
              <WhatWeAreList>
                <ul>
                  <li>
                    <h1>Agendar sessões</h1>
                  </li>
                  <li>
                    <h1>Confirmar sessões</h1>
                  </li>
                  <li>
                    <h1>Reagendar sessões</h1>
                  </li>
                  <li>
                    <h1>Menos evasão clínica</h1>
                  </li>
                </ul>
              </WhatWeAreList>
              <img src={AppPrint} alt={AppPrint} />
            </RowBetween>
          </WhatWeAreDiv3>
        </WhatWeAreRow>
        <FunctionalitiesCol>
          <h1 style={{ fontWeight: 700, fontSize: "32px", color: "#373F41" }}>
            Nossas funcionalidades
          </h1>

          <FunctionalitiesGrid>
            <img style={{ width: "100%" }} src={DashedWave} alt={DashedWave} />
            <img src={CameraIcon} alt={CameraIcon} />
            <img src={DocIcon} alt={DocIcon} />
            <img src={PieceIcon} alt={PieceIcon} />
            <img src={FaceIcon} alt={FaceIcon} />

            <FunctionalitiesCircle
              gridArea="firstCircle"
              type="light"
              // style={{ gridRow: 1, gridColumnStart: 1 }}
            >
              <h1>Recursos terapêuticos</h1>
              <h2>
                Diversos recursos terapêuticos e jogos digitais para serem
                utilizados em tempo real durante as sessões.
              </h2>
            </FunctionalitiesCircle>

            <FunctionalitiesCircle
              gridArea="secondCircle"
              type="light"
              // style={{ gridRow: 1, gridColumnStart: 3 }}
            >
              <h1>Videochamada</h1>
              <h2>
                Utilize nossa sala de videochamada e tenha toda segurança
                necessária para seus atendimentos on-line
              </h2>
            </FunctionalitiesCircle>

            <FunctionalitiesCircle
              gridArea="thirdCircle"
              type="dark"
              // style={{ gridRow: 2, gridColumnStart: 2, marginLeft: -100 }}
            >
              <h1>Prontuários</h1>
              <h2>
                Crie os prontuários on-line de cada paciente, anotações das
                sessões, ficha de anamnese, armazenamento de documentos de forma
                prática e com toda a segurança que esses documentos necessitam.
              </h2>
            </FunctionalitiesCircle>

            <FunctionalitiesCircle
              gridArea="fourthCircle"
              type="dark"
              // style={{ gridRow: 2, gridColumnStart: 4, marginLeft: -200 }}
            >
              <h1>Agenda On-line</h1>
              <h2>
                Organize sua agenda digital e realize o controle de presença dos
                seus pacientes.
              </h2>
            </FunctionalitiesCircle>
          </FunctionalitiesGrid>
        </FunctionalitiesCol>
        <HowItWorksBackground>
          <Col width={"1300px"}>
            <h1 style={{ fontWeight: 700, fontSize: "32px", color: "#E8E3FC" }}>
              Como funciona
            </h1>
            <HowItWorksDiv>
              <ArrowsDiv>
                <img src={Arrow1} alt={Arrow1} />
                <img src={Arrow2} alt={Arrow2} />
                <img src={Arrow1} alt={Arrow1} />
              </ArrowsDiv>
              <HowItWorksCircle>
                <h1>1</h1>
                <h2>Cadastre-se e faça seu login na plataforma</h2>
              </HowItWorksCircle>

              <HowItWorksCircle>
                <h1>2</h1>
                <h2>Cadastre seus pacientes e agende as sessões</h2>
              </HowItWorksCircle>

              <HowItWorksCircle>
                <h1>3</h1>
                <h2>Inicie a videochamada e envie o link para seu paciente</h2>
              </HowItWorksCircle>

              <HowItWorksCircle>
                <h1>4</h1>
                <h2>
                  Pronto! Basta escolher e iniciar os jogos digitais ou recursos
                  terapêuticos
                </h2>
              </HowItWorksCircle>
            </HowItWorksDiv>
          </Col>
        </HowItWorksBackground>
        <LGPDOutterContainer>
          <LGPDInnerContainer>
            <h1>Você sempre seguro</h1>
            <h2>
              Segurança das informações com dados criptografados. Total
              privacidade, segurança e ética.
            </h2>
            <LGPDRow>
              <img src={ISO} alt={ISO} />
              <img src={HIPAA} alt={HIPAA} />
              <img src={LGPDImage} alt={LGPDImage} />
            </LGPDRow>
          </LGPDInnerContainer>
        </LGPDOutterContainer>
        <TestifiesContainer>
          <SideTestify>
            <h1>Rafaela de Abreu A. C. Crisóstomo , Psicólogo(a)</h1>
            <h2>
              “A plataforma é bastante prática e fácil de usar. Organiza bem os
              atendimentos e tem atratividade para o público infantil. Estou
              gostando bastante!”
            </h2>
            <RowCentered style={{ marginTop: 70 }}>
              <img
                src={Attestant1}
                alt={Attestant1}
                style={{ marginLeft: -30, marginRight: 80 }}
              />
              <h3>CRP: 02/24028</h3>
            </RowCentered>
          </SideTestify>
          <MiddleTestify>
            <ColCentered>
              <h1>Depoimentos</h1>
              <MiddleTestifyContent>
                <img src={QuoteLeft} alt={QuoteLeft} />
                <h2>
                  A aplicação destes recursos durante uma sessão online aproxima
                  ainda mais a qualidade de um atendimento virtual em relação
                  aos benefícios que um atendimento presencial poderia
                  proporcionar com exclusividade.
                </h2>
                <img src={QuoteRight} alt={QuoteRight} />
              </MiddleTestifyContent>
            </ColCentered>
          </MiddleTestify>
          <SideTestify>
            <h1>Priscila de Aguiar Bastos, Psicólogo(a)</h1>
            <h2 style={{ marginTop: 35 }}>
              "Realizar meus atendimentos pelo Terapia Interativa faz toda
              diferença na qualidade do meu trabalho. Além de ser um verdadeiro
              consultório virtual com vários recursos facilitadores, tem um
              aspecto indispensável que é a segurança."
            </h2>
            <RowCentered style={{ marginTop: 30 }}>
              <img
                src={Attestant2}
                alt={Attestant2}
                style={{ marginLeft: -30, marginRight: 80 }}
              />
              <h3>CRP: 02/17534</h3>
            </RowCentered>
          </SideTestify>
        </TestifiesContainer>
        <Footer />
        <ModalForm modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        <WhatsAppButton
          href="https://api.whatsapp.com/send/?phone=558181119190&text&app_absent=0"
          target="_blank"
        >
          <img src={WhatsAppImage} alt={WhatsAppImage} />
        </WhatsAppButton>
      </Container>
    </>
  );
}
