import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";

import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles.js";

import ReactGA from "react-ga";
ReactGA.initialize("G-D3M3672C8B");

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyles />
    <Routes />
  </BrowserRouter>,
  document.getElementById("root")
);
