import React, { useEffect } from "react";

import {
  Container,
  InnerContainer,
  Title,
  SemiTitle,
  Paragraph,
} from "./styles";

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { thermsPageEvent } from "../../facebookPixel";



export default function Therms() {

  useEffect(()=>{
    thermsPageEvent()
  },[])
  
  return (
    <>
      <NavBar />
      <Container>
        <InnerContainer>
          <Title>Termos & Condições de Uso</Title>
          <SemiTitle>1. TERMOS</SemiTitle>
          <Paragraph>
            1.1 – Este site, cujo domínio é,{" "}
            <a href="www.terapiainterativa.com.br">
              www.terapiainterativa.com.br
            </a>
            (“SITE”) bem como o Software TERAPIA INTERATIVA (“SOFTWARE”,
            “SISTEMA ou “TERAPIAINTERATIVA”) são de propriedade, mantidos e
            operados por Ferrari Serviço Online de Terapia Interativa LTDA,
            devidamente inscrito no CNPJ nº 37.876.392/0001-67, com sede na Rua
            do Cupim 132, Graças, Recife/PE, CEP nº 52.011-070. O presente
            documento estabelece um Termo de Licença de Uso de Software como
            Serviço - SaaS e é firmado entre a Ferrari Serviço Online de Terapia
            Interativa LTDA e o contratante devidamente qualificado no cadastro
            da conta de acesso (“USUÁRIO” ou “CLIENTE”), que concorda e adere
            integralmente aos presentes termos e condições. Além disso, este
            instrumento visa prestar informações sobre o modo de utilização do
            Software e suas ferramentas, condições, nomenclaturas, direitos e
            deveres.
          </Paragraph>
          <Paragraph>
            Recomendamos que você leia com atenção todo o texto antes de dar
            prosseguimento a navegação e a utilização de nossos serviços.
            Sugerimos, ainda, que caso seja aceito, que você armazene ou imprima
            uma cópia deste contrato, incluindo todas as políticas.
          </Paragraph>
          <Paragraph>
            A título de esclarecimento, consideram-se para este documento as
            seguintes nomenclaturas:
          </Paragraph>
          <Paragraph>
            Administrador: Usuário principal da conta TERAPIA INTERATIVA. O
            Administrador possui o controle da conta, pode fazer alterações,
            realizar pagamentos e é pelo seu e-mail que a plataforma é acessada.
            <br />
            IP: Significa "Internet Protocol", traduzido para português como
            Protocolo da Internet e é um número que identifica um dispositivo
            (computador, impressora, roteador) em uma rede.
            <br /> Link: Ligação entre documentos na Internet. <br />
            Login: É o processo que permite o acesso a um sistema informático,
            controlado por meio de identificação e autenticação do usuário pelas
            credenciais fornecidas por esse mesmo internauta. <br />
            Plug-ins: Programa instalado no navegador que permite a utilização
            de recursos não presentes na linguagem HTML, na qual são criadas as
            páginas da Internet. <br />
            SaaS: Sigla para “Software as a Service”, que em português significa
            Software como Serviço, permite que os Usuários se conectem e usem
            aplicativos baseados em nuvem pela Internet. <br />
            Upload: Ação de enviar dados de um computador local para um
            computador ou servidor remoto.
            <br /> Worm: Programa semelhante aos vírus, com a diferença de ser
            auto replicante, ou seja, cria cópias funcionais de si mesmo e
            infecta outros computadores.
          </Paragraph>
          <Paragraph>
            1.2 - O uso da plataforma TERAPIA INTERATIVA e de todas as
            informações, telas, exibições e produtos, incluindo os mecanismos de
            cadastramento de sites e busca, bem como todos os serviços
            oferecidos pela plataforma, estão sujeitos às condições constantes
            deste termo.
          </Paragraph>
          <Paragraph>
            1.3 - Ao usar os serviços, você concorda com cada uma das condições
            abaixo estabelecidas. Se você não concordar com quaisquer dos termos
            ou condições abaixo estabelecidos, você não estará autorizado a usar
            os serviços para qualquer propósito. Caso você tenha quaisquer
            dúvidas referentes a estas condições, sinta-se à vontade para
            contatar-nos.
          </Paragraph>
          <Paragraph>
            1.3 - Ao usar os serviços, você concorda com cada uma das condições
            abaixo estabelecidas. Se você não concordar com quaisquer dos termos
            ou condições abaixo estabelecidos, você não estará autorizado a usar
            os serviços para qualquer propósito. Caso você tenha quaisquer
            dúvidas referentes a estas condições, sinta-se à vontade para
            contatar-nos.
          </Paragraph>
          <Paragraph>
            1.4 - A utilização dos serviços do site TERAPIA INTERATIVA sem a
            observância das condições constantes deste termo acarretará ao
            usuário, seja ele paciente, psicólogo ou visitante, toda
            responsabilidade por qualquer dano ou prejuízo que possa sofrer ou
            causar.
          </Paragraph>
          <Paragraph>
            1.5 - Ao se cadastrar o usuário fica sujeito a aceitar todos os
            termos aqui descritos, bem como, se obriga a informar os seguintes
            dados ao fazer seu cadastro: nome completo, e-mail, CPF, cidade,
            residência, profissão, CRP ou outros que venham a ser solicitados
            pela plataforma.{" "}
          </Paragraph>
          <Paragraph>
            1.6 – A plataforma TERAPIA INTERATIVA respeita os dados pessoais de
            seus clientes, parceiros e visitantes, sendo certo que a forma de
            tratamento de tais dados está descrita na Política de Privacidade
            que pode ser acessada através da plataforma.
          </Paragraph>
          <SemiTitle>2. O SERVIÇO</SemiTitle>
          <Paragraph>
            2.1 - O serviço fornecido pela TERAPIA INTERATIVA consiste, mas não
            se limita, em criar uma ferramenta que auxilie Psicólogos e
            consultórios de Psicologia em:
          </Paragraph>
          <Paragraph>
            a) Agendamentos Online; b) Controle de Sessões;
            <br />
            c) Impressão de Relatórios;
            <br />
            d) Geração de Boletos;
            <br />
            e) Controle Financeiro;
            <br />
            f) Criação de Formulários de perguntas para prática de Anamnese;
            <br />
            g) Elaboração de Documentos Personalizados;
            <br />
            h) Vídeo Chamadas
            <br />
            i) Jogos on-line
            <br />
          </Paragraph>
          <Paragraph>
            2.1.1 – Os serviços são disponibilizados por meio de Planos mensais
            ou anuais, que são escolhidos de acordo com a conveniência do
            Usuário e cujas características e valores estão sempre vinculados
            expressamente durante a contratação.
          </Paragraph>
          <Paragraph>
            2.1.2 – A TERAPIA INTERATIVA poderá, ainda, fornecer serviços
            extras, como o envio de SMS. Pelos serviços extras serão cobrados
            valores independentes.
          </Paragraph>
          <Paragraph>
            2.2 – O objetivo é licenciar de forma revogável, não exclusiva,
            limitada e intransferível o uso do Terapia Interativa por meio da
            internet, na modalidade de Software as a Service (SaaS), incluindo a
            hospedagem do Sistema e banco de dados.
          </Paragraph>
          <Paragraph>
            2.3 – Os serviços de licença são contratados para a versão atual e
            vigente do Sistema. A TERAPIA INTERATIVA poderá sofrer atualizações
            periodicamente e sem custo, recebendo novas funcionalidades, funções
            aprimoradas, plug-ins e correções de falhas (doravante denominados
            "Atualizações").{" "}
          </Paragraph>
          <Paragraph>
            2.4 – Em nenhuma hipótese a TERAPIA INTERATIVA presta serviços
            psicológicos, laboratoriais, de aconselhamento, de saúde em geral ou
            correlatados.
          </Paragraph>
          <Paragraph>
            2.5 – A TERAPIA INTERATIVA não determina as escolhas que os Usuários
            fazem em relação a avaliações, diagnósticos, tratamentos e
            procedimentos. Apenas possibilita a inserção de informações e
            controle pelos profissionais na área da Psicologia.
          </Paragraph>
          <Paragraph>
            2.6 – Concordando com este termo, o Usuário declara que está
            plenamente ciente que a sua atuação, especialmente quanto a
            diagnósticos, tratamentos, prescrições, qualidade dos serviços
            oferecidos, condições, garantias e valores, é totalmente
            independente da Terapia Interativa e de responsabilidade exclusiva
            de quem efetivamente os executar. Este Software não possui qualquer
            ingerência sobre as mencionadas ações, sendo totalmente isento de
            responsabilidade, seja cível ou criminal.
          </Paragraph>
          <Paragraph>
            2.7 – Não estão inclusos nos serviços qualquer tipo de treinamento
            dos Usuários pela TERAPIA INTERATIVA. Caberá a TERAPIA INTERATIVA,
            de forma livre e ao seu próprio critério, definir as hipóteses
            possíveis de oferecer os treinamentos.{" "}
          </Paragraph>
          <SemiTitle>3. PERÍODO DE TESTE</SemiTitle>
          <Paragraph>
            3.1 – O Usuário poderá realizar um Teste gratuito dos serviços pelo
            período de 15 (quinze) dias.
          </Paragraph>
          <Paragraph>
            3.1.1 – Até o 14º (décimo quarto dia) após a aceitação do Teste, o
            Usuário terá à sua disposição todas as funcionalidades disponíveis
            na Terapia Interativa.{" "}
          </Paragraph>
          <Paragraph>
            3.1.2 – No 15º (décimo quinto) dia será realizada a transição do
            Usuário para a opção de assinar um Plano e realizar o pagamento
            devido ou encerrar o período de Teste e interromper a utilização da
            Terapia Interativa. A transição poderá ocorrer anteriormente, caso o
            Usuário contrate um Plano.
          </Paragraph>

          <Paragraph>
            3.2 – Caso o Usuário opte por encerrar o período de Teste e não
            contratar os serviços, todos os dados inseridos no Software e
            qualquer personalização efetuada durante o Teste, serão armazenados
            de forma segura, conforme padrões de proteção de dados, e excluídos
            permanentemente da plataforma após 1 (um) ano.
          </Paragraph>

          <Paragraph>
            3.3 – Caso o Usuário deseje exclusão imediata dos dados mencionados
            no item (3.2), ele deverá se manifestar por meio dos Contatos
            fornecidos ao final deste Termo.
          </Paragraph>
          <SemiTitle>4. CADASTRO DE USUÁRIOS</SemiTitle>
          <Paragraph>
            4.1 – Para que o Cliente utilize os serviços é necessário se
            registrar na plataforma, tornando-se um Usuário.{" "}
          </Paragraph>
          <Paragraph>
            4.2 – Durante o cadastro, o Cliente criará uma conta de acesso,
            determinando o seu nome de Usuário e senha, sendo de sua exclusiva
            responsabilidade a manutenção do sigilo dessas informações.
          </Paragraph>
          <Paragraph>
            4.2.1 – No caso de Pessoas Jurídicas, o cadastro deverá ser
            realizado por seu representante legal, que tenha poderes para
            contratar.
          </Paragraph>
          <Paragraph>
            4.2.2 – No caso de Pessoas Físicas, apenas maiores de 18 (dezoito)
            anos poderão se cadastrar.
          </Paragraph>
          <Paragraph>
            4.3 – Os dados solicitados pela TERAPIA INTERATIVA para cadastro
            são: nome completo, e-mail e senha. Estes dados são utilizados
            exclusivamente pela Terapia Interativa para acesso ao sistema e
            contato profissional com o Usuário, quando necessário, sendo
            armazenados de forma segura, conforme descrito na Política de
            Privacidade.
          </Paragraph>
          <Paragraph>
            4.4 – O Usuário Administrador sempre será aquele que fizer o
            primeiro cadastro no Software.
          </Paragraph>
          <Paragraph>
            4.5 – As informações constantes no cadastro são de exclusiva
            responsabilidade dos Usuários. Caso a imprecisão, incompletude ou
            ausência dos dados causem danos de qualquer espécie, ficará o
            Usuário responsável pela reparação, bem como, sujeito a outras
            penalidades legais.
          </Paragraph>
          <Paragraph>
            4.6 – O Usuário deve notificar a TERAPIA INTERATIVA imediatamente em
            caso de uso indevido de sua conta ou de qualquer outra violação de
            segurança. A TERAPIA INTERATIVA não se responsabiliza pelas ações e
            danos que poderão ser causados pelo acesso irregular por terceiros.
          </Paragraph>
          <SemiTitle>5. DO PAGAMENTO E REEMBOLSO</SemiTitle>
          <Paragraph>
            5.1 – Para utilizar o Software, com exceção do período de Teste e do
            Plano Estudante, o Usuário deverá pagar valores a TERAPIA
            INTERATIVA, de forma que a licença do Software é condicionada ao
            recebimento das quantias devidas.
          </Paragraph>
          <Paragraph>
            5.1.1 – Os preços e formas de pagamento são variáveis de acordo com
            os Planos contratados, o número de licenças, a periodicidade, o
            número de Usuários (quantidade de profissionais cadastrados no
            sistema) e serão sempre informados expressamente no momento da
            contratação.
          </Paragraph>
          <Paragraph>
            5.1.2 – Os valores são cobrados em moeda corrente do Brasil (real) e
            não são reembolsáveis, ainda que o Cliente não faça uso dos
            serviços.
          </Paragraph>
          <Paragraph>
            5.1.3 – Por meio deste aceite, o Usuário autoriza a cobrança de
            todos os serviços indicados no extrato de assinatura.
          </Paragraph>
          <Paragraph>
            {" "}
            5.2 – Os pagamentos são realizados via cartão de crédito e boleto
            bancário, por meio da plataforma para automação financeira Iugu. Ou
            seja, o Usuário é direcionado e insere as informações de pagamento
            diretamente no sistema Iugu.{" "}
          </Paragraph>
          <Paragraph>
            A TERAPIA INTERATIVA não possui acesso aos dados de cartão de
            crédito dos Usuários e não os armazena. Por essa razão, indica-se
            que, antes de efetuar qualquer transação financeira, o Usuário leia
            atentamente os Termos de Uso e Políticas de Privacidade e Segurança
            do Iugu que não possuem relação com o TERAPIA INTERATIVA e com este
            documento.
          </Paragraph>
          <Paragraph>
            5.3 – A TERAPIA INTERATIVA enviará avisos de vencimento dos
            pagamentos com 05 (cinco) dias de antecedência.{" "}
          </Paragraph>
          <Paragraph>
            5.4 – Em caso de inadimplemento ou caso não seja possível efetuar a
            cobrança de algum dos encargos faturados na data de vencimento, a
            TERAPIA INTERATIVA avisará ao Usuário que o serviço está com
            pagamento vencido, dando uma carência de 2 (dois) dias corridos para
            quitação. Ao final dos 2 (dois) dias, não havendo a confirmação do
            pagamento de renovação do serviço, o acesso ao Software será
            suspenso.
          </Paragraph>
          <Paragraph>
            5.4.1 – Após 15 (quinze) dias da suspensão do Usuário, a TERAPIA
            INTERATIVA enviará nova comunicação indicando que o serviço não pode
            ser utilizado por falta de pagamento. Ao completar 45 (quarenta e
            cinco) dias de suspensão, será enviado novo aviso. Após 1 (um) ano
            de inatividade da conta, os dados do Usuário serão removidos
            permanentemente da Plataforma.{" "}
          </Paragraph>
          <Paragraph>
            5.4.2 – Caso o Usuário deseje exclusão imediata dos dados
            mencionados no item (5.4.1), ele deverá se manifestar por meio dos
            Contatos fornecidos ao final deste Termo.
          </Paragraph>
          <Paragraph>
            5.5 – Em caso de cancelamento de Plano pelo Usuário, a TERAPIA
            INTERATIVA cobrará multa de 30% (trinta por cento) sobre os valores
            ainda devidos, reembolsando o Usuário da diferença.{" "}
          </Paragraph>
          <Paragraph>
            5.6 – Salvo disposição em contrário em um formulário específico, no
            caso de serviços extras, como o envio de SMS, os pagamentos serão
            realizados da seguinte forma:
          </Paragraph>
          <Paragraph>
            5.6.1 – As tarifas são cobradas, antecipadamente, conforme Plano do
            serviço extra contratado;
          </Paragraph>
          <Paragraph>
            5.6.2 – No serviço de envio de avisos via SMS será indicado ao
            Usuário o valor unitário cobrado por cada mensagem, por meio de
            Termo Aditivo a estes Termos de Uso, que aparecerá em uma caixa de
            aceite e que deverá ser ativada pelo próprio Cliente;
          </Paragraph>
          <Paragraph>
            5.6.3 – As tarifas serão devidas em razão da disponibilização dos
            serviços adquiridos;{" "}
          </Paragraph>
          <Paragraph>
            5.6.4 – As obrigações de pagamento dos serviços extras não são
            canceláveis e as tarifas pagas não são reembolsáveis, uma vez que a
            TERAPIA INTERATIVA trabalha com serviço de terceiros que não
            permitem essas ações.
          </Paragraph>
          <Paragraph>
            5.6.5 – As tarifas de assinatura são baseadas em períodos mensais
            que começam no dia do pagamento e perduram conforme o volume
            adquirido.
          </Paragraph>
          <Paragraph>
            5.7 – A TERAPIA INTERATIVA reserva-se o direito de modificar e/ou
            atualizar os preços cobrados pelo uso do Software, atendendo a
            critérios de necessidade, conveniência ou flutuações do mercado.
          </Paragraph>
          <SemiTitle>
            6. RESPONSABILIDADE DOS PSICÓLOGOS E CONSULTÓRIOS
          </SemiTitle>
          <Paragraph>
            6.1 – Ao aceitar o presente termo, o Usuário afirma que está ciente
            e assume todos os seus deveres profissionais e obrigações impostas
            pelo Código de Ética Profissional do Psicólogo e demais legislações
            competentes, em especial a responsabilidade por dados dos pacientes
            e dever de sigilo, reforçando que a TERAPIA INTERATIVA segue normas
            internacionais rigorosas de segurança e proteção destes dados,
            conforme descrito na Política de Privacidade.
          </Paragraph>
          <Paragraph>
            6.2 – O Usuário é o único proprietário dos dados inseridos por ele
            na plataforma e, por essa razão, concorda que todas as informações
            expostas são de sua responsabilidade, autorizando a TERAPIA
            INTERATIVA a utilizar os dados de cadastro do Usuário para fins
            estritamente necessários para prestação dos serviços aqui
            contratados e conforme descrito na Política de Privacidade.
          </Paragraph>
          <Paragraph>
            6.3 – Do mesmo modo, o Usuário responsabiliza-se exclusivamente por
            qualquer compartilhamento não autorizado de informações de pacientes
            com terceiros.
          </Paragraph>
          <Paragraph>
            6.4 – O Usuário que praticar ações ilícitas reconhece e concorda em
            indenizar, reembolsar, defender e isentar qualquer terceiro,
            inclusive o proprietário da TERAPIA INTERATIVA, de reclamação,
            notificação, intimação, ação judicial ou extrajudicial ou ainda de
            qualquer responsabilidade, dano, custo, honorários advocatícios ou
            despesas decorrentes de qualquer infração cometida pelo Usuário ou
            qualquer pessoa agindo em seu nome.
          </Paragraph>
          <Paragraph>
            6.5 – Todas as obrigações fiscais e tributárias, assim como
            trabalhistas, consumeristas, previdenciárias, penais, cíveis,
            disciplinares ou de qualquer outra natureza, pessoais ou do
            consultório, serão exclusivamente do Usuário.
          </Paragraph>
          <SemiTitle>7 – NÍVEL DE SERVIÇO</SemiTitle>
          <Paragraph>
            7.1 – A TERAPIA INTERATIVA empreende seus esforços para zelar pela
            eficiência dos servidores, adotando junto a todos os Usuários as
            medidas necessárias para evitar prejuízos ao funcionamento da
            plataforma. Com isso, a TERAPIA INTERATIVA compromete-se a oferecer
            e tornar o Software disponível por 98% (noventa e oito por cento) do
            tempo em cada mês civil.
          </Paragraph>
          <Paragraph>
            7.2 – O compromisso de Nível de Serviço de 98% (noventa e oito por
            cento) não se aplica caso as circunstâncias de indisponibilidade
            resultem de:
          </Paragraph>
          <Paragraph>
            a) Falhas de disco rígido, cujo tempo necessário para cópia dos
            dados do servidor de backup varia de 16 (dezesseis) a 48 (quarenta e
            oito) horas;
            <br />
            b) Queima ou problema físico em peças de hardware, podendo levar até
            8 (oito) horas para a efetiva troca de peças;
            <br />
            c) Falhas de conectividade, sendo que a TERAPIA INTERATIVA garante
            possuir, no mínimo, 2 (duas) rotas de conectividade de internet,
            caso venham a apresentar instabilidade parcial ou total;
            <br />
            d) Falhas na conexão de internet;
            <br />
            e) Falhas de programação do Software que seja de responsabilidade do
            Usuário ou sobrecarga do servidor causada por programação não
            otimizada do Usuário ou de outro Cliente da TERAPIA INTERATIVA no
            mesmo servidor, por se tratar de um serviço compartilhado de
            hospedagem de sites;
            <br />
            f) Interrupções necessárias para ajustes técnicos ou manutenção,
            como, por exemplo, a troca ou instalação de discos rígidos,
            memórias, placas de rede, instalação e atualização de softwares,
            serão informadas com antecedência e se realizarão,
            preferencialmente, em horários noturnos e de baixo movimento;
            <br />
            g) Intervenções emergenciais decorrentes da necessidade de preservar
            a segurança do Site, destinadas a evitar ou fazer cessar a atuação
            de “hackers” ou destinadas a implementar correções de segurança
            (patches);
            <br />
            h) Suspensão da prestação dos serviços contratados por determinação
            de autoridades competentes ou por descumprimento de cláusulas dos
            presentes Termos de Uso e/ou das Políticas de Privacidade;
            <br />
            i) Descumprimento da obrigação de comunicação prévia pelo Usuário a
            respeito de aumento de tráfego;
            <br />
            j) Caso de aviso prévio por parte da TERAPIA INTERATIVA com fins
            específicos de manutenção dos servidores;
            <br />
            k) Casos fortuitos ou de força maior, nos termos do artigo 393 do
            Código Civil;
            <br />
            l) Quaisquer atos ou omissões do Usuário ou de terceiros.
            <br />
          </Paragraph>
          <Paragraph>
            7.3 – As interrupções para manutenção na prestação dos serviços
            acessórios, que não impliquem em prejuízo para a operacionalidade
            principal da TERAPIA INTERATIVA, perdurarão pelo tempo necessário à
            supressão das irregularidades detectadas, não podendo, no entanto,
            superar o prazo de 30 (trinta) dias corridos. Exemplos de serviços
            acessórios: estatística de acesso, publicação de páginas (FTP,
            Frontpage), agendamento de tarefas (crontab) e acesso ao painel de
            controle.{" "}
          </Paragraph>
          <SemiTitle>8. PROPRIEDADE INTELECTUAL</SemiTitle>
          <Paragraph>
            8.1 – A TERAPIA INTERATIVA tem seu uso licenciado por modalidade de
            serviço “Software as a Service” (SaaS) e não venda. A titularidade e
            os direitos relativos ao Software, aos materiais, aplicativos,
            funcionalidades que o acompanham e quaisquer cópias pertencem
            exclusivamente a TERAPIA INTERATIVA. Assim, o Usuário declara que
            está ciente que os direitos a propriedade intelectual sobre o
            TERAPIA INTERATIVA não são objeto destes Termos e Condições de Uso e
            Política de Privacidade.
          </Paragraph>
          <Paragraph>
            8.2 – O Usuário não está autorizado a utilizar, sob qualquer forma
            ou pretexto, as marcas, suas variações, domínios e sinais que sejam
            da TERAPIA INTERATIVA ou estiverem expostos no Software.
          </Paragraph>
          <Paragraph>
            8.3 – Todo o conteúdo do Software, incluindo programas, bases de
            dados, arquivos, textos, desenhos, fotos, layouts, cabeçalhos e
            demais elementos, foi criado, desenvolvido ou cedido a TERAPIA
            INTERATIVA, sendo, portanto, de propriedade exclusiva da TERAPIA
            INTERATIVA ou a ele licenciado e encontra-se protegido pelas leis
            brasileiras, como a Lei do Software (Lei n. 9.609/98), Lei de
            Direitos Autorais (Lei n. 9.610/98), Lei de Propriedade Industrial
            (Lei n. 9.279/96) e tratados internacionais que versam sobre
            direitos de propriedade intelectual.
          </Paragraph>
          <Paragraph>
            8.4 – São proibidas: a cópia, venda, licença, distribuição,
            transferência, modificação, adaptação, tradução, preparação de obras
            derivadas, descompilação, aplicação de engenharia reversa,
            desmontagem ou tentativa de obtenção do código-fonte do Software e a
            reprodução, integral ou parcial, de qualquer conteúdo da TERAPIA
            INTERATIVA.
          </Paragraph>
          <Paragraph>8.5 – Além disso, o Usuário não poderá</Paragraph>
          <Paragraph>
            a) Tomar qualquer providência para burlar ou anular as regras de
            segurança ou utilização de conteúdo fornecidas, implementadas ou
            impostas por qualquer funcionalidade (incluindo, sem limitação, a
            funcionalidade de gerenciamento de direitos digitais) oferecida no
            Software;
            <br />
            b) Usar o Software para acessar, copiar, transferir, transcodificar
            ou retransmitir conteúdo violando qualquer lei ou os direitos de
            terceiros;
            <br />
            c) Remover, ocultar ou alterar avisos de direito autoral, de marca
            registrada ou outros avisos de direitos dA TERAPIA INTERATIVA
            afixados ou contidos no Software ou acessados em conjunto com o
            Software.
          </Paragraph>
          <Paragraph>
            8.6 – O Usuário que violar as proibições contidas na legislação
            sobre propriedade intelectual e nestes Termos e Condições de Uso e
            Política de Privacidade, será responsabilizado, civil e
            criminalmente, pelas infrações cometidas, além de poder ser
            penalizado na plataforma.
          </Paragraph>
          <SemiTitle>9. OBRIGAÇÕES DAS PARTES</SemiTitle>
          <Paragraph>9.1 – São obrigações da TERAPIA INTERATIVA:</Paragraph>
          <Paragraph>
            9.1.1 – Realizar os serviços nos moldes contratados pelo Usuário,
            promovendo o bom funcionamento do Software e disponibilizar acesso
            aos serviços de suporte para esclarecimento de dúvidas.
          </Paragraph>
          <Paragraph>
            9.1.2 – Comunicar qualquer alteração dos serviços aos Usuários.
          </Paragraph>
          <Paragraph>
            9.1.3 – Efetuar alterações no Software, decorrentes de ordem legal,
            sem qualquer custo para o Cliente e de acordo com a política de
            atualização do Software.
          </Paragraph>
          <Paragraph>
            9.1.4 – Seguir as normas internacionais de segurança e proteção de
            dados, conforme descrito na Política de Privacidade, mantendo
            absoluto sigilo sobre as operações, dados, materiais, documentos,
            especificações técnicas ou comerciais do Cliente que sejam
            fornecidas durante o uso do Software.
          </Paragraph>
          <SemiTitle>9.2. SÃO OBRIGAÇÕES DO USUÁRIO:</SemiTitle>
          <Paragraph>
            9.2.1 – Utilizar o Software conforme os critérios de utilização
            definidos pela TERAPIA INTERATIVA, sem alterar a sua programação,
            quebrar senhas ou realizar procedimentos que venham causar
            prejuízos.
          </Paragraph>
          <Paragraph>
            9.2.2 – Responsabilizar-se pelo teor das informações e documentos
            que introduzir no Software, uma vez que os servidores da TERAPIA
            INTERATIVA apenas armazenam esses dados e não contribuem para sua
            produção.
          </Paragraph>
          <Paragraph>
            9.2.3 – Manter todas as informações de cartão de crédito completas e
            válidas para o faturamento dos serviços, caso esse seja o meio de
            pagamento utilizado.
          </Paragraph>
          <Paragraph>
            9.2.4 – Efetuar os pagamentos de todas as licenças e tarifas
            contratadas, nos prazos e formas estabelecidos.
          </Paragraph>
          <Paragraph>
            9.2.5 – Respeitar os Direitos Autorais e de Propriedade Intelectual
            da TERAPIA INTERATIVA.
          </Paragraph>
          <Paragraph>
            9.2.6 – Zelar pelo sigilo das informações confidenciais de seus
            pacientes.
          </Paragraph>
          <Paragraph>
            9.2.7 – Agir em conformidade aos deveres inerentes à sua profissão,
            ao Código de Ética do Profissional da Psicologia e demais
            legislações em vigor.
          </Paragraph>
          <SemiTitle>10. RESTRIÇÕES E PENALIDADES</SemiTitle>
          <Paragraph>10.1 – O Usuário não poderá:</Paragraph>

          <Paragraph>
            10.1.1 – Usar o Software de qualquer maneira que possa danificar,
            desativar, sobrecarregar ou prejudicar os serviços da TERAPIA
            INTERATIVA ou de qualquer modo que possa interferir no uso e
            aproveitamento dos serviços por parte de terceiros.
          </Paragraph>
          <Paragraph>
            10.1.2 – Difamar, ofender, importunar, perseguir, ameaçar ou violar
            os direitos legais (os direitos de privacidade, por exemplo) de
            terceiros.
          </Paragraph>
          <Paragraph>
            10.1.3 – Fazer upload, publicar, enviar por e-mail, transmitir ou
            divulgar qualquer conteúdo inadequado, difamatório, transgressor,
            obsceno ou ilegal.
          </Paragraph>
          <Paragraph>
            10.1.4 – Fazer upload, publicar, enviar por e-mail, transmitir ou
            divulgar qualquer conteúdo que infrinja qualquer direito de patente,
            marca comercial, direito autoral, segredo comercial ou outro direito
            da TERAPIA INTERATIVA ou de terceiros, salvo se o Usuário for o
            proprietário dos respectivos direitos ou tiver permissão do
            proprietário para publicar o referido conteúdo.
          </Paragraph>
          <Paragraph>
            10.1.5 – Assumir a identidade de outra pessoa ou entidade,
            falsificar ou excluir quaisquer atribuições do autor, avisos legais
            ou outros avisos adequados, bem como designações, rótulos de origem,
            fonte do Software ou outro material.
          </Paragraph>
          <Paragraph>
            10.1.6 – Restringir ou inibir qualquer outro Usuário no uso e
            aproveitamento dos serviços da TERAPIA INTERATIVA.
          </Paragraph>
          <Paragraph>
            10.1.7 – Usar os serviços da TERAPIA INTERATIVA para qualquer
            finalidade ilegal ou não autorizada.
          </Paragraph>
          <Paragraph>
            10.1.8 – Remover quaisquer avisos de direito autoral, de marca
            comercial ou outros direitos contidos nos serviços da TERAPIA
            INTERATIVA.
          </Paragraph>
          <Paragraph>
            10.1.9 – Prejudicar ou interromper os serviços, servidores ou as
            redes conectadas aos serviços da TERAPIA INTERATIVA ou desobedecer a
            quaisquer exigências, procedimentos, políticas ou regulamentações
            das redes conectadas aos serviços do Software.
          </Paragraph>
          <Paragraph>
            10.1.10 – Usar robô, mecanismo de busca, aplicativo de
            pesquisa/recuperação de sites ou outro dispositivo para recuperar ou
            indexar qualquer parte dos serviços da TERAPIA INTERATIVA ou coletar
            informações sobre os Usuários.
          </Paragraph>
          <Paragraph>
            10.1.11 – Enviar conteúdo com propaganda enganosa afirmando que tal
            conteúdo é patrocinado ou endossado pela TERAPIA INTERATIVA.
          </Paragraph>
          <Paragraph>
            10.1.12 – Criar contas de acesso de Usuário por meios automatizados
            ou com intenções falsas ou fraudulentas.
          </Paragraph>
          <Paragraph>
            10.1.13 – Promover ou fornecer informações sobre atividades ilegais
            ou promover lesões ou danos físicos a qualquer grupo ou indivíduo.
          </Paragraph>
          <Paragraph>
            10.1.14 – Transmitir quaisquer vírus, worms, defeitos, cavalos de
            Tróia ou quaisquer itens de natureza destrutiva.
          </Paragraph>
          <Paragraph>
            10.2 – O Usuário que praticar ações ilícitas e/ou proibidas nestes
            Termos e Condições de Uso e/ou Política de Privacidade poderá ser
            advertido, ter seus serviços suspensos, ser excluído do sistema,
            além de responder civil e criminalmente por qualquer dano decorrente
            da violação.
          </Paragraph>
          <Paragraph>
            10.3 – A Terapia Interativa se reservará ao direito de suspender o
            acesso de qualquer usuário que:
          </Paragraph>
          <Paragraph>
            • Promover discurso de ódio;
            <br />• Incentivar a violência ou o desrespeito à terceiros e
            minorias;
            <br />• Promover atos de racismo, homofobia e afins;
            <br />• Desrespeitar outros usuários;
            <br />• Praticar qualquer ato fraudulento;
            <br />• Divulgar conteúdos explícitos e/ou pornográficos;
            <br />• Desrespeitar direitos autorais de terceiros;
            <br />• Coletar dados pessoais de terceiros de maneira indevida;
            <br />• Promover qualquer atividade ilícita;
            <br />• Desrespeitar quaisquer das disposições destes Termos de Uso;
          </Paragraph>
          <Paragraph>
            Se o usuário violar quaisquer das disposições acima estabelecidas,
            ou quaisquer outros aspectos destes Termos de Uso, a plataforma se
            reservará ao direito de suspender ou rescindir o acesso do usuário,
            independentemente do envio de qualquer notificação, e sem que haja a
            devolução de quaisquer valores eventualmente pagos.
          </Paragraph>
          <SemiTitle>11. LIMITAÇÃO DE RESPONSABILIDADE</SemiTitle>
          <Paragraph>
            11.1 – A TERAPIA INTERATIVA engaja seus melhores esforços para
            informar, atender e proteger o Usuário. Entretanto, o Usuário é o
            único responsável pela utilização do Software e pelas informações,
            senhas, conteúdo e documentos inseridos.{" "}
          </Paragraph>
          <Paragraph>
            11.2 – Em nenhuma hipótese, a TERAPIA INTERATIVA ou seus
            representantes serão responsabilizados por:{" "}
          </Paragraph>
          <Paragraph>
            11.2.1 – Diagnósticos feitos pelos profissionais de Psicologia,
            ainda que amparados em informações contidas no Software, bem como
            tratamentos, orientações, prescrições e orçamentos. Do mesmo modo, a
            TERAPIA INTERATIVA não responde pela qualidade na execução dos
            serviços pelos Psicólogos e profissionais da área.
          </Paragraph>
          <Paragraph>
            11.2.2 – Danos que o Usuário possa experimentar por ações de
            terceiros, falhas no servidor, na conexão de rede, interações
            maliciosas como vírus, falhas no hardware, falta de energia e
            indisponibilidade no ambiente operacional (equipamentos);{" "}
          </Paragraph>
          <Paragraph>
            11.2.3 – Prejuízos que o Usuário possa ter em sites de internet
            acessíveis por links incluídos no Software;
          </Paragraph>
          <Paragraph>
            11.2.4 – Danos e prejuízos que o Usuário possa experimentar em
            decorrência do mau uso do Software em desconformidade com estes
            Termos e Condições de Uso, Políticas de Privacidade, com a lei, com
            os costumes ou com a ordem pública;{" "}
          </Paragraph>
          <Paragraph>
            11.3 – Fica esclarecido que o presente serviço não estabelece entre
            as partes qualquer vínculo empregatício, societário ou associativo,
            permanecendo cada parte como única responsável por todas as suas
            respectivas despesas e encargos.
          </Paragraph>
          <SemiTitle>12. DISPONIBILIDADE DA FERRAMENTA E GARANTIAS</SemiTitle>
          <Paragraph>
            12.1 – Apesar da dedicação constante da TERAPIA INTERATIVA no
            sentido de fornecer informações precisas, atualizadas, corretas e
            completas, o Software poderá conter erros técnicos, inconsistências
            ou erros tipográficos.{" "}
          </Paragraph>
          <Paragraph>
            12.2 – A TERAPIA INTERATIVA é disponibilizada na Internet, não
            incluindo as seguintes garantias:
          </Paragraph>
          <Paragraph>
            12.2.1 – Adequação do Software para um determinado fim específico
            solicitado pelo Usuário;{" "}
          </Paragraph>
          <Paragraph>
            12.2.2 – Inexistência de defeitos, erros ou falhas;
          </Paragraph>
          <Paragraph>
            12.2.3 – Correção de problemas, danos ou prejuízos causados por
            decisões tomadas pelo Usuário com base em informações fornecidas
            pela ferramenta, assim como defeitos ou erros decorrentes de
            negligência, imprudência ou imperícia do Cliente;
          </Paragraph>
          <Paragraph>
            12.2.4 – Problemas provenientes de caso fortuito ou força maior;{" "}
          </Paragraph>
          <Paragraph>
            12.3 – A TERAPIA INTERATIVA se reserva o direito de modificar o
            Software, bem como a configuração, a apresentação, o conteúdo, as
            funcionalidades, as ferramentas ou qualquer outro elemento,
            inclusive o seu cancelamento.
          </Paragraph>
          <SemiTitle>13. DADOS PESSOAIS, PRIVACIDADE E SEGURANÇA</SemiTitle>
          <Paragraph>
            A TERAPIA INTERATIVA dispõe de uma política específica para regular
            a coleta, guarda e utilização de dados pessoais, bem como a sua
            proteção e segurança. A Política de Privacidade pode ser acessada no
            site da TERAPIA INTERATIVA, na área de Segurança da Informação.
          </Paragraph>
          <SemiTitle>14. IDIOMA</SemiTitle>
          <Paragraph>
            14.1 – Toda a documentação legal do Software, incluindo os presentes
            Termos e Condições de Uso, foi elaborada em língua portuguesa. A
            TERAPIA INTERATIVA poderá, a seu exclusivo critério, disponibilizar
            traduções de tais documentos no Software apenas para conveniência do
            Usuário, a seu exclusivo critério.{" "}
          </Paragraph>
          <Paragraph>
            14.2 – A versão em português (BR) destes Termos e Condições de Uso e
            da Política de Privacidade é a única consentida pela TERAPIA
            INTERATIVA. Em caso de contradição ou divergência entre a versão em
            português e eventual tradução para qualquer outro idioma,
            prevalecerá sempre a versão em língua portuguesa.
          </Paragraph>
          <SemiTitle>15. DO PRAZO E DA DURAÇÃO</SemiTitle>
          <Paragraph>
            15.1 – A presente contratação de serviços entra em vigor na data do
            aceite destes Termos Condições de Uso e Política de Privacidade pelo
            Usuário, incluindo o período de Testes, e será vigente por prazo
            indeterminado, sendo sucessivamente renovada por meio da confirmação
            de pagamento.
          </Paragraph>
          <Paragraph>
            15.2 – Estes Termos e Condições de Uso e a Política de Privacidade
            têm duração indefinida e permanecerão em vigor enquanto o Software
            estiver ativo. Da mesma forma, o acesso e a utilização da ferramenta
            e dos recursos oferecidos têm, a princípio, duração indeterminada.
            No entanto, a TERAPIA INTERATIVA reserva-se o direito de suspender
            e/ou cancelar o acesso ao Software, a algumas de suas partes ou
            recursos
          </Paragraph>
          <SemiTitle>
            16. ALTERAÇÕES DOS TERMOS E CONDIÇÕES DE USO E POLÍTICA DE
            PRIVACIDADE
          </SemiTitle>
          <Paragraph>
            16.1 – A TERAPIA INTERATIVA poderá revisar, modificar e/ou
            atualizar, a qualquer momento, cláusulas ou disposições contidas
            nestes Termos e Condições de Uso ou na Política de Privacidade.{" "}
          </Paragraph>
          <Paragraph>
            16.2 – A versão atualizada valerá para o uso do Software realizado a
            partir de sua divulgação pela TERAPIA INTERATIVA. A continuidade de
            acesso ou utilização da TERAPIA INTERATIVA depois da divulgação e do
            aceite das modificações confirmará a vigência dos novos Termos e
            Condições de Uso ou da nova Política de Privacidade pelos Usuários.
          </Paragraph>
          <Paragraph>
            16.3 – Caso o Usuário não esteja de acordo com a alteração, poderá
            rescindir seu vínculo com a TERAPIA INTERATIVA, clicando no botão
            referente a não concordância/recusa, encontrado ao final da
            divulgação do novo texto. Essa rescisão não eximirá, no entanto, o
            Usuário de cumprir com todas as obrigações assumidas sob as versões
            precedentes da Política de Privacidade e dos Termos e Condições de
            Uso.
          </Paragraph>
          <SemiTitle>17. LEI APLICÁVEL E FORO DE ELEIÇÃO</SemiTitle>
          <Paragraph>
            17.1 – O Software é controlado, operado e administrado no território
            nacional, podendo ser acessado por qualquer dispositivo conectado à
            Internet, independentemente de sua localização geográfica.{" "}
          </Paragraph>
          <Paragraph>
            17.2 – Em vista das diferenças que podem existir entre as
            legislações locais e nacionais, ao acessar o Software, o Usuário
            concorda que a legislação aplicável para fins destes Termos e
            Condições de Uso e da Política de Privacidade será aquela vigente na
            República Federativa do Brasil.
          </Paragraph>
          <Paragraph>
            17.3 – A TERAPIA INTERATIVA e o Usuário concordam que o Foro de
            Recife, Estado de Pernambuco, Brasil, será o único competente para
            dirimir qualquer questão ou controvérsia oriunda ou resultante do
            uso do Software, renunciando expressamente a qualquer outro, por
            mais privilegiado que seja, ou venha a ser.
          </Paragraph>
          <SemiTitle>18. REGISTRO PÚBLICO</SemiTitle>

          <Paragraph>
            Estes Termos e Condições de Uso, bem como a Política de Privacidade,
            encontram-se registrados em Cartório de Registro de Títulos e
            Documentos da Comarca de Recife, Estado de Pernambuco, Brasil.
            Eventuais atualizações destes Termos, bem como de qualquer das
            Políticas, serão imediatamente registradas no mesmo cartório.
          </Paragraph>
          <SemiTitle>19. CONTATO</SemiTitle>
          <Paragraph>
            19.1 - A TERAPIA INTERATIVA disponibiliza canais de atendimento para
            receber todas as comunicações que o Usuário desejar fazer. Pelo
            telefone: (81) 99978-6240, de Segunda à Sexta das 08h00 às 12h00 e
            das 14h00 às 18h00 (horário de Brasília), exceto sábados, domingos e
            feriados. Por e-mail: para o endereço eletrônico
            terapiainterativaonline@gmail.com. Pelo endereço: Rua do Cupim 132,
            Graças, Recife/PE, CEP nº 52.011-070.
          </Paragraph>
          <Paragraph>
            19.2. Todas as solicitações devem sempre estar acompanhadas das
            informações de contato do Usuário, para que possamos finalizar o
            atendimento. Os Usuários receberão a confirmação de recebimento do
            contato e a TERAPIA INTERATIVA terá até 3 (três) dias para
            retorná-los.
          </Paragraph>

          <Paragraph style={{ alignSelf: "center" }}>
            O presente documento foi redigido e disponibilizado em 07 de julho
            de 2021.
          </Paragraph>

          <Title style={{ marginTop: 50 }}>
            POLÍTICA DE PRIVACIDADE – PRIVACY POLICY
          </Title>

          <SemiTitle>1. INFORMAÇÕES GERAIS</SemiTitle>
          <Paragraph>
            A presente Política de Privacidade contém informações sobre coleta,
            uso, armazenamento, tratamento e proteção de dados pessoais dos
            usuários e visitantes do site www.terapiainterativa.com.br.{" "}
          </Paragraph>
          <Paragraph>
            Esta Política de Privacidade aplica-se a todos os pacientes,
            psicólogos cadastrados e visitantes do site
            www.terapiainterativa.com e integra os Termos e Condições de Uso do
            mesmo, com a razão social denominada Ferrari Serviço Online de
            Terapia Interativa LTDA, devidamente inscrito no CNPJ nº
            37.876.392/0001-67, com sede na Rua do Cupim 132, Graças, Recife/PE,
            CEP nº 52.011-070.{" "}
          </Paragraph>
          <Paragraph>
            Ao aceitar essa Política de Privacidade, o usuário e visitantes,
            concorda integralmente com seus termos e também consente livre e
            expressamente que a TERAPIA INTERATIVA colete, use, armazene e faça
            o tratamento de suas informações, que serão necessárias para que o
            serviço ofertado seja prestado em sua integralidade.
          </Paragraph>
          <Paragraph>
            O presente documento foi elaborado em conformidade com a Lei Geral
            de Proteção de Dados Pessoais (Lei nº 13.709/18), o Marco Civil da
            Internet (Lei nº 12.965/14), além do selo HIPPA, sigla dada para
            Health Insurance Portability and Accountability Act, é uma lei
            aprovada em 1996 nos EUA que regulamenta a proteção de dados na área
            da saúde, visando proteger suas informações digitais. Não obstante,
            o presente documento poderá ser atualizado em decorrência de
            eventual atualização normativa, em casos de alteração nas
            funcionalidades do site www.terapiainterativa.com.br, ou mesmo por
            conveniência, razão pela qual se convida o usuário a consultar
            periodicamente esta seção.
          </Paragraph>
          <Paragraph>
            "Dados Pessoais" são informações que podem ser usadas para
            identificá-lo, direta ou indiretamente, sozinho ou em conjunto com
            outras informações. Isso inclui informações como seu nome completo,
            endereço de e-mail e número de telefone.
          </Paragraph>
          <Paragraph>
            “Dados Pessoais Sensíveis” são dados que revelam a origem racial ou
            étnica, opiniões políticas, crenças religiosas ou filosófica,
            filiação sindical, dados genéticos, dados biométricos para fins de
            identificação pessoal inequívoca, dados relativos ao estado de saúde
            ou à vida sexual e/ou orientação sexual de uma pessoa
          </Paragraph>
          <Paragraph>
            A TERAPIA INTERATIVA coleta, usa, divulga e processa Dados Pessoais,
            conforme descrito nesta Política de Privacidade, inclusive para
            operar e melhorar os serviços e nossos negócios, conforme descrito
            mais detalhadamente nesta Política de Privacidade.
          </Paragraph>
          <Paragraph>
            Podemos criar dados anônimos ou sem identificação a partir de dados
            pessoais excluindo componentes de dados (como seu nome, endereço de
            e-mail ou ID de rastreamento vinculável) que tornam os dados
            pessoalmente identificáveis a você, por ofuscação ou por outros
            meios. Nosso uso de dados anônimos e sem identificação não está
            sujeito a esta Política de Privacidade.
          </Paragraph>
          <Paragraph>
            A TERAPIA INTERATIVA não coleta diretamente Dados Pessoais
            Sensíveis, mas tem plena consciência que estes dados podem ser
            coletados diretamente pelos Usuários no seu relacionamento com seus
            pacientes/clientes e armazenados para processamento no Software.
            Desta forma, a TERAPIA INTERATIVA mantém os mesmos processos de
            proteção e segurança a estes dados, mas alerta que os Usuários,
            controladores dos dados, devem seguir os mesmos critérios de
            proteção, não se responsabilizando a TERAPIA INTERATIVA pelos atos
            praticados pelos Usuários, conforme descrito mais detalhadamente
            nesta Política de Privacidade.
          </Paragraph>
          <Paragraph>
            Alertamos que todo o texto deve ser lido com atenção e, caso você
            não concorde com o conteúdo de nossa Política de Privacidade e/ou
            Termos e Condições de Uso, não dê prosseguimento a navegação ou a
            utilização de nossos serviços. Recomendamos, ainda, que caso seja
            aceito, que você armazene ou imprima uma cópia deste contrato,
            incluindo todas as políticas.
          </Paragraph>
          <SemiTitle>2. DEFINIÇÕES</SemiTitle>
          <Paragraph>
            Para os fins desta Política de Privacidade, os termos abaixo
            listados possuem as seguintes definições:{" "}
          </Paragraph>
          <Paragraph>
            a) Terapia Interativa: Forma de denominação da empresa Ferrari
            Serviço Online de Terapia Interativa LTDA;
            <br />
            b) Plataforma da Terapia Interativa: Forma de denominação da
            plataforma fornecida e operada pela empresa Ferrari Serviço Online
            de Terapia Interativa LTDA; <br />
            c) Psicólogo Cadastrado: É o profissional da área de psicologia
            cadastrado na plataforma da Terapia Interativa que prestará serviços
            aos pacientes; <br />
            d) Paciente: É o paciente cadastrado na plataforma da Terapia
            Interativa que contratará os serviços do psicólogo cadastrado;
            <br />
            e) Visitante: É a pessoa natural, que não esteja na condição de
            psicólogo cadastrado ou de paciente, que visita a plataforma da
            Terapia Interativa; <br />
            f) Dados Pessoais: qualquer informação relacionada à pessoa natural
            identificada ou identificável. São considerados dados pessoais, por
            exemplo: nome, número de CPF, número de RG, fotografias, impressões
            digitais, endereços de e-mails, dentre outros;
            <br />
            g) Dados Pessoais Sensíveis: informações de pessoa natural
            relacionadas a origem racial ou étnica, convicção religiosa, opinião
            política, filiação a sindicato ou a organização de caráter
            religioso, filosófico ou político, dado referente à saúde ou à vida
            sexual, dado genético ou biométrico; <br />
            h) Tratamento: é toda e qualquer operação realizada com dados
            pessoais, como a coleta, o compartilhamento, o armazenamento e até a
            exclusão;
            <br />
            i) Controlador: é a pessoa natural ou jurídica a quem competem as
            decisões referentes ao tratamento de dados pessoais;
            <br />
            j) Operador: é a pessoa natural ou jurídica que realiza o tratamento
            de dados pessoais em nome do controlador;
            <br />
            l) Titular: é a pessoa natural a quem se referem os dados pessoais
            que são objeto de tratamento. <br />
            m) Cookies: São arquivos armazenados no dispositivo do usuário para
            que as ações e preferências do mesmo sejam “lembradas”
            temporariamente, melhorando e facilitando a navegação e usabilidade
            do sistema. <br />
          </Paragraph>
          <SemiTitle>3. DISPONIBILIZAÇÃO</SemiTitle>
          <Paragraph>
            O presente documento foi redigido e disponibilizado em 11/08/2021.{" "}
          </Paragraph>
          <SemiTitle>4. PRIVACIDADE E SEGURANÇA DO USUÁRIO</SemiTitle>
          <Paragraph>
            4.1. Proteger sua privacidade é muito importante para nós. Suas
            senhas são armazenadas nos servidores da A TERAPIA INTERATIVA de
            forma criptografada por meio do Sistema de Criptografia Avançado. A
            segurança das conexões também está garantida pelo protocolo https.
            Ao transmitir informações sensíveis, você deve sempre garantir que
            seu navegador seja capaz de validar os certificados adequados.
          </Paragraph>
          <Paragraph>
            4.2. A TERAPIA INTERATIVA preza pela proteção das informações
            confiadas a nós, bem como pela qualidade do sistema, mantendo uma
            conduta de alto respeito à intimidade de seus Usuários e dos
            pacientes/clientes dos nossos Usuários. Dessa forma recomenda-se aos
            Usuários conduta semelhante e o máximo de cuidado no sentido de
            proteger a privacidade e confidencialidade de seus pacientes, nos
            termos do Código de Ética Profissional do Psicólogo, da Constituição
            Federal, do Código de Defesa do Consumidor, do Código Penal e todas
            as normas brasileiras e internacionais, como HIPAA e GDPR.
            Ressalta-se que os atos praticados pelos Usuários não são de
            responsabilidade do TERAPIA INTERATIVA, incluído os descumprimentos
            desta cláusula.{" "}
          </Paragraph>
          <Paragraph>
            4.3. A TERAPIA INTERATIVA recomenda fortemente que o Usuário
            formalize o consentimento de registro eletrônico de informações dos
            seus pacientes/clientes, em atendimento ao Regulamento Geral de
            Proteção de Dados e que, conforme cláusula 4.2, observe todos os
            requisitos de segurança e proteção.
          </Paragraph>
          <Paragraph>
            4.4. Aceitando o presente documento, o Usuário reconhece e concorda
            que a TERAPIA INTERATIVA divulgue as informações de sua conta,
            quando assim for exigido por autoridades públicas, sendo que os atos
            de preservação ou divulgação objetivam:{" "}
          </Paragraph>
          <Paragraph>
            a) Satisfazer qualquer legislação, regulamentação, processo judicial
            ou solicitação governamental aplicável;
            <br />
            b) Impor estas Políticas de Privacidade e os Termos e Condições de
            Uso, incluindo a investigação de suas possíveis violações;
            <br />
            c) Detectar, impedir ou abordar problemas técnicos, de fraude e de
            segurança;
            <br />
            d) Atender a solicitações de suporte do Usuário;
            <br />
            e) Proteger os direitos, a propriedade ou a segurança da A TERAPIA
            INTERATIVA e seus Usuários e do público. <br />
          </Paragraph>
          <SemiTitle>5. COLETA DE DADOS PESSOAIS</SemiTitle>
          <Paragraph>
            Em nossa plataforma, as informações são coletadas das seguintes
            formas:
          </Paragraph>
          <Paragraph>
            Os dados pessoais do paciente podem ser coletados, dentre outras
            maneiras, quando o mesmo criar e/ou atualizar sua conta na
            plataforma da Terapia Interativa, sendo certo que poderão ser
            tratados, exemplificadamente, dados como: nome, e-mail, CPF, cidade,
            residência, profissão, dentre outros.{" "}
          </Paragraph>
          <Paragraph>
            O tratamento de tais dados permite que a plataforma identifique o
            paciente, garantindo segurança e melhorando sua usabilidade e
            navegabilidade na plataforma.
          </Paragraph>
          <Paragraph>
            Os dados pessoais do psicólogo cadastrado podem ser coletados,
            dentre outras maneiras, quando o mesmo criar e/ou atualizar sua
            conta na plataforma da Terapia Interativa, sendo certo que poderão
            ser tratados, exemplificadamente, dados como: nome, informações
            profissionais, RG, CPF, endereço, informações curriculares, dentre
            outros.
          </Paragraph>
          <Paragraph>
            Ainda, poderá a plataforma, de forma automática, coletar dados
            pessoais dos visitantes, pacientes e/ou psicólogos cadastrados que
            estejam em navegação pelo seu website, como, por exemplo, IP com
            data e hora da conexão, sistema operacional utilizado,
            geolocalização, dentre outros. Também poderá coletar dados pessoais
            que estejam em posse de terceiros, como, instituições bancárias ou
            órgãos de proteção ao crédito.
          </Paragraph>
          <Paragraph>
            Caso a plataforma da Terapia Interativa venha a facultar aos
            pacientes e psicólogos a utilização de redes sociais, como, por
            exemplo, o LinkedIn ou o Facebook para realização de cadastro ou
            login em sua plataforma, poderá tratar informações pessoais dos seus
            usuários que estejam disponíveis e aptas para compartilhamento em
            tal rede social.
          </Paragraph>
          <Paragraph>
            A TERAPIA INTERATIVA não coleta Dados Pessoais de crianças. Se você
            souber que uma criança nos forneceu Dados Pessoais, entre em contato
            conosco por meio do formulário de CONTATO no site ou diretamente
            pelo e-mail suporte.terapiainterativa@gmail.com. Se nós constatarmos
            que uma criança nos forneceu Dados Pessoais, tomaremos as devidas
            medidas para remover as informações e cancelar a conta da criança em
            questão.
          </Paragraph>
          <SemiTitle>
            6. DA FINALIDADE DO TRATAMENTO DOS DADOS PESSOAIS
          </SemiTitle>
          <Paragraph>
            Os dados pessoais poderão ser tratados pela Terapia Interativa para
            exemplificadamente, mas não limitadamente:{" "}
          </Paragraph>
          <Paragraph>
            • O exercício de suas atividades;
            <br />
            • Melhoria e aprimoramento na usabilidade e na navegação de sua
            plataforma e de seu website, promovendo ao usuário mais efetividade,
            eficiência e satisfação
            <br />
            • Contato;
            <br />• Prestação de suporte técnico e melhoras nas medidas de
            segurança que são ou que poderão ser implantadas pela Terapia
            Interativa; <br />
            • Permitir o acesso dos usuários cadastrados ao conteúdo da
            plataforma;
            <br />
            • Criação de perfis de consumidores; <br />
            • Publicidade, sem o uso de dados sensíveis;
            <br />
            • Combinação de quaisquer das finalidades acima; <br />
          </Paragraph>
          <SemiTitle>7. COMPARTILHAMENTO DE DADOS PESSOAIS</SemiTitle>
          <Paragraph>
            A Terapia Interativa não promove a comercialização dos dados
            pessoais de seus pacientes, psicólogos cadastrados e/ou visitantes.{" "}
          </Paragraph>
          <Paragraph>
            O compartilhamento dos dados pessoais do paciente, mesmo aqueles de
            natureza sensível, é feito com o psicólogo escolhido pelo próprio
            paciente, para que o profissional possa exercer seus serviços.{" "}
          </Paragraph>
          <Paragraph>
            No que diz respeito aos prontuários de cada paciente, o mesmo só
            poderá ser acessado pelo psicólogo cadastrado escolhido pelo
            paciente, não podendo a plataforma, seus prepostos e demais
            estranhos ao processo terapêutico terem acesso a tais informações,
            conforme determina a ética do conselho nacional de psicologia. Para
            dar efetividade e maior segurança a essa questão será usada
            criptografia em todos os prontuários.
          </Paragraph>
          <Paragraph>
            Em alguns casos específicos, e sempre respeitando os termos da Lei
            Geral de Proteção de Dados, a Terapia Interativa poderá promover o
            compartilhamento dos dados com terceiros, como, por exemplo, nas
            seguintes hipóteses:{" "}
          </Paragraph>
          <Paragraph>
            • Compartilhamento com prestadores de serviços para o tratamento dos
            dados em nome da Terapia Interativa, como, por exemplo, serviços de
            armazenamento em nuvem, serviços analíticos, serviços de
            processamento financeiro (plataforma pagamentos ou semelhantes),
            dentre outros;
            <br />
            • Compartilhamento com terceiros quando exigido pela legislação
            aplicável; <br />
            • Compartilhamento com terceiros quando exigido pela Autoridade
            Nacional de Proteção de Dados – ANPD; <br />
            • Compartilhamento com terceiros quando exigido por determinação
            judicial; <br />
            • Compartilhamento com terceiros por determinação de autoridades
            públicas e/ou governamentais; <br />
            • Compartilhamento com terceiros para o cumprimento de obrigações
            legais; <br />
          </Paragraph>
          <Paragraph>
            Compartilhamento das informações: com parceiros: Seus dados poderão
            ser compartilhados com parceiros para melhoria na prestação de
            serviço, respeitando a inviolabilidade e sigilo das comunicações
            feitas na internet. Nenhuma informação pessoal poderá ser divulgada
            publicamente, apenas de forma genérica não identificável. A única
            exceção está em casos em que essas informações forem exigidas por
            lei ou ordem judicial.
          </Paragraph>
          <Paragraph>
            Autenticação e integração com o Google Calendar. Caso necessite de
            uma integração com o Google dos eventos PSICOMANAGER o processo será
            realizado da seguinte maneira: Será enviado ao usuário uma permissão
            para autenticar em sua conta do google para uma autorização e
            consequente integração. Essa autorização é diretamente enviada para
            o google, que gera a TERAPIA INTERATIVA uma autenticação em forma de
            "token", permitindo que os dados sejam trafegados entre a TERAPIA
            INTERATIVA e o Google. Quando o usuário criar um evento na TERAPIA
            INTERATIVA essa informação será enviada via ambiente seguro ao
            Google, que irá criar um calendário chamado "TERAPIA INTERATIVA " no
            Google Calendar deste usuário. A TERAPIA INTERATIVA armazenará
            somente o "identificador" desse calendário criado e dos eventos
            enviados para o Google. Não há nenhuma possibilidade que a TERAPIA
            INTERATIVA acesse, nem compartilhe as informações da conta do google
            do usuário, apenas o próprio usuário que permitiu essa integração.
          </Paragraph>
          <Paragraph>
            A Terapia Interativa, sempre que possível, firmará instrumento
            contratual com os terceiros destinatários de dados pessoais,
            garantindo que tais terceiros adotarão elevado padrão de cuidado e
            respeito com os dados pessoais transferidos.
          </Paragraph>
          <SemiTitle>
            8. INTEGRAÇÃO COM APLICATIVOS DE TERCEIROS E SEUS DADOS
          </SemiTitle>
          <Paragraph>
            8.1. Ao instalar ou habilitar o uso de aplicativos de terceiros com
            os serviços da TERAPIA INTERATIVA, o Usuário estará permitindo que
            os fornecedores desses aplicativos acessem determinados dados
            pessoais. Recomendamos a leitura atenta dos Termos de Uso e
            Políticas de Privacidade dessas aplicações antes de prosseguir com a
            utilização.
          </Paragraph>
          <Paragraph>
            8.2. Isso exime a TERAPIA INTERATIVA de se responsabilizar por
            qualquer divulgação, modificação ou eliminação dos dados
            compartilhados por meio da integração com aplicativos de terceiros,
            de sorte que apenas os serviços contidos no Software são de
            responsabilidade da TERAPIA INTERATIVA. A TERAPIA INTERATIVA não se
            responsabiliza ou garante a disponibilidade dos serviços de
            terceiros.
          </Paragraph>
          <SemiTitle>9. DOS DADOS TORNADOS PÚBLICOS</SemiTitle>
          <Paragraph>
            Os dados do psicólogo cadastrado, relativos ao exercício
            profissional, ficarão públicos para usuários e visitantes, no
            tocante a sua prestação de serviço, informações curriculares, área
            de abordagem bem como qualificação, de forma a viabilizar a escolha
            do paciente pelo profissional de sua preferência.{" "}
          </Paragraph>
          <Paragraph>
            Os dados pessoais dos pacientes estarão protegidos, de modo que só
            poderá ter acesso aos seus dados o psicólogo escolhido pelo mesmo,
            salvo nas hipóteses previstas no item III acima.
          </Paragraph>
          <SemiTitle>
            10. DOS DADOS COLETADOS PELO PSICÓLOGO CADASTRADO
          </SemiTitle>
          <Paragraph>
            Quando o psicólogo cadastrado coletar dados pessoais de seu
            paciente, o psicólogo cadastrado será considerado controlador dos
            dados pessoais. Nesta hipótese, caso tais dados sejam armazenados na
            plataforma da Terapia Interativa, a plataforma figurará como mera
            operadora.{" "}
          </Paragraph>
          <Paragraph>
            O paciente declara ciência que não recairá sobre à Terapia
            Interativa qualquer responsabilidade acerca dos dados coletados e
            tratados pelo psicólogo cadastrado, que será o único e exclusivo
            responsável pelo tratamento adequado de tais dados.
          </Paragraph>
          <SemiTitle>11. DO ARMAZENAMENTO DE DADOS PESSOAIS</SemiTitle>
          <Paragraph>
            Os dados pessoais do usuário e visitantes são armazenados pela
            plataforma durante o período necessário para a prestação do serviço
            ou o cumprimento das finalidades previstas no presente documento,
            conforme disposto no art. 15, I da Lei 13.709/18.{" "}
          </Paragraph>
          <Paragraph>
            Os dados podem ser removidos ou anonimizados a pedido do usuário,
            excetuando os casos em que a lei oferecer tratamento diverso.
          </Paragraph>
          <Paragraph>
            Ainda, os dados pessoais dos usuários podem ser conservados após o
            término do seu tratamento nas hipóteses previstas no art. 16 da Lei
            13.709/18.
          </Paragraph>
          <SemiTitle>12. DAS MEDIDAS DE SEGURANÇA</SemiTitle>
          <Paragraph>
            A Terapia Interativa se preocupa com a segurança das informações
            pessoais de seus pacientes, psicólogos cadastrados e visitantes, de
            forma que, para a garantia da segurança da informação sob tratamento
            serão adotadas soluções que levem em consideração: as técnicas
            adequadas, os custos de aplicação, a natureza, o âmbito, o contexto
            e as finalidades do tratamento e os riscos para os direitos e
            liberdades do usuário.
          </Paragraph>
          <Paragraph>
            As informações referentes a pagamentos feitos por cartão de crédito
            estarão protegidas por criptografia automática do protocolo HTTPS,
            garantindo a transmissão segura e confidencial de dados.
          </Paragraph>
          <Paragraph>
            Os prontuários dos pacientes estarão protegidos por criptografia e
            só poderão ser acessados pelos seus respectivos psicólogos
            cadastrados.{" "}
          </Paragraph>
          <Paragraph>
            Assim, considerando que a Terapia Interativa adota as técnicas de
            segurança necessárias, a mesma não se responsabilizará por eventuais
            incidentes de segurança da informação causados pelo próprio
            paciente, psicólogo cadastrado e/ou por terceiros, que possam
            provocar, de modo acidental ou ilícito, a destruição, a perda, a
            alteração, a divulgação ou o acesso não autorizado aos dados
            pessoais sob tratamento.
          </Paragraph>
          <SemiTitle>13.DO USO DE COOKIES</SemiTitle>
          <Paragraph>
            A Terapia Interativa se utiliza, em seu website e em sua plataforma,
            de cookies para aperfeiçoar a navegação e melhorar a usabilidade do
            paciente, psicólogo cadastrado ou visitante, que poderá, caso
            queira, se opor ou limitar o registro de cookies. Nesta hipótese,
            sua experiência de navegação poderá ser afetada e o funcionamento
            regular do website ou da plataforma poderá sofrer alterações.
          </Paragraph>
          <SemiTitle>14.DOS DIREITOS DO TITULAR DOS DADOS PESSOAIS</SemiTitle>
          <Paragraph>
            Quando a Terapia Interativa figurar na posição de controladora de
            dados pessoais, o titular de tais dados poderá exercer os direitos
            previstos na Lei 13.709/2018 através do e-mail
            terapiainterativaonline@gmail.com, sendo a empresa Ferrari Serviço
            Online de Terapia Interativa LTDA a pessoa jurídica competente para
            tratar sobre tais questões.
          </Paragraph>
          <Paragraph>
            Para o exercício de direitos, deverá o titular informar: (i) seu
            nome completo; (ii) número do CPF, (iii) direito que deseja exercer;
            (iv) todo e qualquer documento ou argumento que possa demonstrar ou
            justificar o exercício de seu direito.{" "}
          </Paragraph>
          <SemiTitle>15.DISPOSIÇÕES GERAIS</SemiTitle>
          <Paragraph>
            A Terapia Interativa se reserva ao direito de modificar essa
            Política de Privacidade a qualquer momento, de forma que, eventuais
            alterações surtirão efeito no momento de sua publicação.
          </Paragraph>
          <Paragraph>
            Os pacientes, psicólogos cadastrados e visitantes que utilizam o
            website ou a plataforma da Terapia Interativa concordam com os
            termos presentes neste Política de Privacidade, de forma que, caso
            alguma disposição específica deste instrumento venha a ser
            considerada inválida, as demais permanecerão vigentes e eficazes.
          </Paragraph>
          <Paragraph>
            Esta Política de Privacidade será regida e interpretada de acordo
            com as Leis Brasileiras, especialmente de acordo com a Lei Federal
            nº 12.965/2014 (Marco Civil da Internet) e com a Lei Federal nº
            13.709/2018 (Lei Geral de Proteção de Dados), sendo competente para
            dirimir eventuais dúvidas decorrentes deste documento o foro da sede
            da empresa.
          </Paragraph>
          <SemiTitle>16.CONTATO</SemiTitle>
          <Paragraph>
            16.1. A TERAPIA INTERATIVA disponibiliza canais de atendimento para
            receber todas as comunicações que o Usuário desejar fazer. Pelo
            telefone: (81) 98111-9190, de Segunda à Sexta das 08h00 às 12h00 e
            das 14h00 às 18h00 (horário de Brasília), exceto sábados, domingos e
            feriados. Por e-mail: para o endereço
            eletrônico:suporte.terapiainterativa@gmail.com.{" "}
          </Paragraph>
          <Paragraph>
            16.2. Todas as solicitações devem sempre estar acompanhadas das
            informações de contato do Usuário, para que possamos finalizar o
            atendimento. Os Usuários receberão a confirmação de recebimento do
            contato e a TERAPIA INTERATIVA terá até 3 (três) dias para
            retorná-los.
          </Paragraph>
        </InnerContainer>
      </Container>
      <Footer />
    </>
  );
}
