import React, { useState } from "react";

import { Link } from "react-router-dom";

import {
  Container,
  InnerContainer,
  TabsContainer,
  Tab,
  TabText,
  LoginButton,
  ButtonsContainer,
  TestButton,
  MenuIcon,
  MobileContainer,
  MobileTab,
} from "./styles";

import Logo from "../../assets/Home/logoTerapiaInterativaNavBar.svg";
import MenuImage from "../../assets/NavBar/menuIcon.svg";

export default function NavBar(props) {
  const [mobileTabActive, setMobileTabActive] = useState(false);
  return (
    <Container>
      <InnerContainer>
        <Link to="/">
          <img src={Logo} alt={Logo} />
        </Link>
        <TabsContainer>
          <Link to="/">
            <Tab isActive={props.activePage === 0}>
              <h1>Home</h1>
            </Tab>
          </Link>
          <Link to="/planos">
            <Tab isActive={props.activePage === 1}>
              <h1>Planos</h1>
            </Tab>
          </Link>
          <Link to="/contato">
            <Tab isActive={props.activePage === 2}>
              <h1>Contato</h1>
            </Tab>
          </Link>
        </TabsContainer>

        <ButtonsContainer>
          <LoginButton>
            <a
              href="https://app.terapiainterativa.com.br/"
              target="_blank"
              rel="App Terapia Interativa"
            >
              <h1>Fazer login</h1>
            </a>
          </LoginButton>
          <TestButton onClick={props.handleModal}>
            <h1>Teste gratuitamente</h1>
          </TestButton>
        </ButtonsContainer>
        <MenuIcon
          src={MenuImage}
          alt={MenuImage}
          onClick={() => setMobileTabActive(!mobileTabActive)}
        />
      </InnerContainer>
      <MobileContainer isActive={mobileTabActive}>
        <Link to="/">
          <MobileTab
            style={{ borderTop: "1px solid" }}
            isActive={props.activePage === 0}
          >
            <h1>Home</h1>
          </MobileTab>
        </Link>
        <Link to="/planos" isActive={props.activePage === 1}>
          <MobileTab>
            <h1>Planos</h1>
          </MobileTab>
        </Link>
        <Link to="/contato" isActive={props.activePage === 2}>
          <MobileTab>
            <h1>Contato</h1>
          </MobileTab>
        </Link>
        <MobileTab isActive={false}>
          <a
            href="https://app.terapiainterativa.com.br/"
            target="_blank"
            rel="App Terapia Interativa"
          >
            <h1>Login</h1>
          </a>
        </MobileTab>
        <MobileTab onClick={props.handleModal} isActive={false}>
          <h1>Teste gratuitamente</h1>
        </MobileTab>
      </MobileContainer>
    </Container>
  );
}
