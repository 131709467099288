import React, { useState,useEffect } from "react";

import {
  Container,
  InnerContainer,
  FormContainer,
  TitleContainer,
  FieldCol,
  InputField,
  TextAreaField,
  SendButton,
  ContactDataDiv,
  Row,
  MediaButton,
} from "./styles";

import FacebookIcon from "../../assets/Contact/facebookIcon.svg";
import InstagramIcon from "../../assets/Contact/instagramIcon.svg";
import MailIcon from "../../assets/Contact/mail.svg";
import PhoneIcon from "../../assets/Contact/phone.svg";

import {contactPageEvent} from '../../facebookPixel'

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import ModalForm from "../../components/ModalForm";

import API from "../../services/api";
import { ToastContainer, toast } from "react-toastify";

import ReactGA from "react-ga";

export default function Contact(props) {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const submitContact = (obj) => {
    return API.post(
      `https://app.terapiainterativa.com.br/gc/index/enviar-email-contato`,
      obj
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || mail === "" || phone === "" || message === "") {
      toast.warn("Preencha todos os campos...", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }

    const obj = new URLSearchParams();
    obj.append("cto_nome_pessoa", name);
    obj.append("cto_email", mail);
    obj.append("cto_fone", phone);
    obj.append("cto_mensagem", message);
    obj.append("cto_url", "https:terapiainterativa.com.br/contato");

    await submitContact(obj)
      .then((resp) => {
        if (!resp.data.error) {
          toast.success(resp.data.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(resp.data.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Error Cadastro, tente novamente mais tarde.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(()=>{
    contactPageEvent()
  },[])

  return (
    <div style={{ maxWidth: "100vw" }}>
      <NavBar activePage={2} handleModal={handleModal} />
      <Container>
        <InnerContainer>
          <FormContainer>
            <TitleContainer>
              <h1>Entre em contato</h1>
              <h2>
                Quer saber mais sobre a Terapia Interativa? Não deixe de seguir
                a gente em nossas redes sociais.
              </h2>
            </TitleContainer>
            <FieldCol>
              <h1>Nome</h1>
              <InputField
                value={name}
                placeholder="Digite seu nome"
                onChange={(e) => setName(e.target.value)}
              />
            </FieldCol>
            <FieldCol>
              <h1>E-mail</h1>
              <InputField
                value={mail}
                placeholder="Digite seu e-mail"
                onChange={(e) => setMail(e.target.value)}
              />
            </FieldCol>
            <FieldCol>
              <h1>Telefone</h1>
              <InputField
                value={phone}
                placeholder="Digite seu telefone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </FieldCol>
            <FieldCol>
              <h1>Nome</h1>
              <TextAreaField
                spellCheck={false}
                value={message}
                placeholder="Digite sua mensagem"
                onChange={(e) => setMessage(e.target.value)}
              />
            </FieldCol>
            <SendButton onClick={handleSubmit}>
              <h1>Enviar mensagem</h1>
            </SendButton>
          </FormContainer>
          <ContactDataDiv>
            {/*<>
              <h1>Fale conosco</h1>
              <Row>
                <img src={PhoneIcon} alt={PhoneIcon} />
                <h2>(34) 90000-0000</h2>
              </Row>
              <Row>
                <img src={MailIcon} alt={MailIcon} />
                <h2>contato@terapiainterativa.com.br</h2>
              </Row>
            </>*/}
            <h1 style={{ marginTop: 50 }}>Siga nossas redes sociais</h1>
            <Row>
              <a
                href="https://www.facebook.com/terapiainterativa"
                target="_blank"
                rel="Facebook"
              >
                <MediaButton>
                  <img src={FacebookIcon} alt={FacebookIcon} />
                  <h1>Facebook</h1>
                </MediaButton>
              </a>
              <a
                href="https://www.instagram.com/terapiainterativaonline"
                target="_blank"
                rel="Instagram"
              >
                <MediaButton>
                  <img src={InstagramIcon} alt={InstagramIcon} />
                  <h1>Instagram</h1>
                </MediaButton>
              </a>
            </Row>
          </ContactDataDiv>
        </InnerContainer>
      </Container>
      <Footer />
      <ModalForm modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
