import styled from "styled-components";

import TestifyBackground from "../../assets/Home/testifiesBackground.svg";
import LGPDBackground from "../../assets/Home/lgpd/lgpdbg.svg";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: -100px;
  }
`;

export const BannerRow = styled.div`
  width: 1300px;
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    img:nth-child(2) {
      width: 85%;
      margin-left: 45px;
    }
  }
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 500px;
  margin-top: 60px;

  h1 {
    color: #373f41, 100%;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
  }

  h2 {
    color: #737b7d;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    margin-top: 15px;
  }

  @media (max-width: 800px) {
    max-width: none;
    padding: 0;
    text-align: center;
    width: 90%;
    align-items: center;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 12px;
    }
  }
`;

export const TestButton = styled.div`
  width: 270px;
  background: #8e7ad6;
  box-shadow: 0px 0px 24px #e8e3fc;
  border-radius: 4px;
  margin-top: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px 0;
  h1 {
    color: #e8e3fc;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-left: 10px;
  }

  &:hover {
    background: #2b186c;
  }

  transition: 0.3s;

  cursor: pointer;
`;

export const WhatWeAreRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-top: 130px;

  @media (max-width: 800px) {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: "what1" "what2" "what3";
  }
`;

export const WhatWeAreDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  height: 530px;
  margin-top: 100px;

  background: #e8e3fc;
  border-radius: 8px;

  padding: 10px 15px;

  text-align: center;

  h1 {
    color: #373f41;
    font-weight: 500;
    font-size: 24px;
    margin-top: 20px;
  }
  h2 {
    margin-top: 10px;
    color: #737b7d;
    font-weight: 400;
    font-size: 18px;
  }

  img {
    margin-top: 103px;
    width: 80%;
  }

  @media (max-width: 800px) {
    grid-area: what2;

    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: auto;
    grid-template-areas: "image" "title";
    margin-top: 20px;
    height: auto;
    width: 100vw;
    border-radius: 0;

    img {
      grid-area: image;
      width: 30vw;
      margin-top: 0;
      margin-right: 10px;
    }
    div {
      margin-left: 20px;
    }
    h1 {
      font-size: 20px;
      width: 50vw;
    }
    h2 {
      font-size: 14px;
      width: 50vw;
    }
  }
`;

export const WhatWeAreDiv2 = styled.div`
  height: 600px;
  width: 600px;

  background-color: #8e7ad6;
  border-radius: 8px;

  padding: 0 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  h1 {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 32px;
    margin-top: 30px;
  }
  h2 {
    color: #fff;
    font-size: 18px;
    margin-top: 50px;
  }
  img {
    margin-top: 54px;
  }

  @media (max-width: 800px) {
    display: grid;
    width: 100vw;
    height: auto;
    grid-area: what1;
    margin-top: 20px;
    padding: 5px;
    padding-bottom: 20px;

    border-radius: 0;

    grid-template-columns: 100vw;
    grid-template-rows: auto auto auto;
    grid-template-areas: "image1" "title1" "sub1";

    img {
      max-width: 98%;
      grid-area: image1;
    }
    h1 {
      max-width: 97%;
      grid-area: title1;
    }
    h2 {
      max-width: 97%;
      font-size: 14px;
      grid-area: sub1;
    }
  }
`;

export const WhatWeAreDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  height: 530px;

  margin-top: 100px;

  background: #e8e3fc;
  border-radius: 8px;

  padding: 10px 15px;

  text-align: center;

  h1 {
    color: #373f41;
    font-weight: 500;
    font-size: 24px;
    margin-top: 20px;
  }
  h2 {
    margin-top: 10px;
    color: #737b7d;
    font-weight: 400;
    font-size: 18px;
  }

  img {
    margin-right: -22px;
    margin-top: 22px;
  }

  @media (max-width: 800px) {
    grid-area: what3;

    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: auto;
    grid-template-areas: "image" "title";

    margin-top: 20px;
    border-radius: 0;

    height: auto;
    width: 100vw;

    img {
      grid-area: image;
      width: 15vw;
      height: auto;
      margin: 0;
    }
    ul {
      display: none;
    }
    div {
      margin-right: 20px;
    }
    h1 {
      font-size: 20px;
      width: 60vw;
    }
    h2 {
      font-size: 14px;
      width: 60vw;
    }
  }
`;

export const RowCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WhatWeAreList = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;

  ul {
    color: #2b186c;
    margin: 0;
    padding: 0;
  }
  ul li {
    margin: 0;
    padding: 0;
  }

  h1 {
    color: #2b186c;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "auto")};

  max-width: 100vw;

  @media (max-width: 800px) {
    &:nth-child(1) {
      margin-left: 10px;
    }
  }
`;

export const FunctionalitiesCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  z-index: 20;

  width: 290px;
  height: 290px;

  border-radius: 50%;

  background: ${(props) => (props.type === "light" ? "#D9FFE3" : "#F3E3FE")};
  box-shadow: 0px 0px 32px 0px #90a4ae33;

  grid-area: ${(props) => props.gridArea};

  h1 {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 18px;
    margin-top: -10px;
    color: #3d3d3d;
  }

  h2 {
    font-size: 14px;
    color: #677176;
    margin-top: 10px;
    max-width: 210px;
    text-align: center;
  }

  @media (max-width: 800px) {
    width: 180px;
    height: 180px;
    h1 {
      font-size: 14px;
    }
    h2 {
      font-size: 12px;
    }
  }
`;

export const FunctionalitiesGrid = styled.div`
  display: grid;
  width: 1300px;
  height: 550px;
  margin-top: 30px;

  grid-template-rows:
    275px
    275px;

  grid-template-columns: 470px 180px 525px 125px;

  grid-template-areas:
    "firstCircle icon1 secondCircle icon2"
    "icon3 thirdCircle icon4 fourthCircle";
  /* grid-template-rows:
    275px
    275px;

  grid-template-columns: 470px 180px 525px 125px; */

  /* grid-template-areas:
    "firstCircle icon1 secondCircle icon2"
    "icon3 thirdCircle icon4 fourthCircle"; */

  img:nth-child(1) {
    position: absolute;
    width: 100vw;
    left: 0;
    margin-top: 150px;
    z-index: 1;
  }

  img:nth-child(2) {
    grid-area: icon1;
    margin-top: 90px;
    grid-row: 1;
    grid-column-start: 2;
  }
  img:nth-child(3) {
    grid-area: icon2;
    margin-top: 60px;
    grid-row: 1;
    grid-column-start: 4;
  }
  img:nth-child(4) {
    grid-area: icon3;
    margin-top: 140px;
    grid-row: 2;
    grid-column-start: 1;
  }
  img:nth-child(5) {
    grid-area: icon4;
    margin-top: 100px;
    grid-row: 2;
    grid-column-start: 3;
    margin-left: 150px;
  }

  @media (max-width: 800px) {
    width: 98vw;
    margin-left: 1vw;
    grid-template-columns: 47vw 48vw;
    grid-template-rows: 120px 120px 120px 120px;
    grid-template-areas:
      "icon1 firstCircle"
      "secondCircle icon2"
      "icon3 thirdCircle"
      "fourthCircle icon4";

    img:nth-child(1) {
      width: 1100px;
    }
    img:nth-child(2) {
      margin-left: 30px;
      margin-top: -1px;
      grid-row: unset;
      grid-column-start: unset;
    }
    img:nth-child(3) {
      margin-left: 40px;
      padding-top: 10px;
      grid-row: unset;
      grid-column-start: unset;
    }
    img:nth-child(4) {
      padding-left: 10px;
      margin-top: 70px;
      grid-row: unset;
      grid-column-start: unset;
    }
    img:nth-child(5) {
      margin-left: 50px;
      margin-top: 70px;
      grid-row: unset;
      grid-column-start: unset;
    }
  }
`;

export const FunctionalitiesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 1300px;

  margin-top: 150px;

  @media (max-width: 800px) {
    width: 100vw;
    h1:nth-child(1) {
      margin-left: 10px;
    }
  }
`;

export const HowItWorksBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background: #725cc0;

  padding: 40px;
  margin-top: 130px;
`;

export const HowItWorksDiv = styled.div`
  width: 1300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 800px) {
    width: 100vw;
    height: 1300px;
    flex-direction: column;
  }
`;
export const ArrowsDiv = styled.div`
  position: absolute;
  width: 1300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: 150px;
  }

  img:nth-child(1) {
    margin-top: 180px;
    margin-left: -10px;
  }
  img:nth-child(2) {
    margin-top: -180px;
    margin-left: -25px;
  }
  img:nth-child(3) {
    margin-top: 180px;
    margin-left: -30px;
  }
  @media (max-width: 800px) {
    width: 100vw;
    height: 1300px;
    flex-direction: column;

    img {
      transform: rotate(90deg);
    }

    img:nth-child(1) {
      margin-top: -5px;
      margin-left: -180px;
    }
    img:nth-child(2) {
      margin-top: 35px;
      margin-left: 180px;
    }
    img:nth-child(3) {
      margin-top: 40px;
      margin-left: -180px;
    }
  }
`;

export const HowItWorksCircle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  width: 250px;
  height: 250px;

  border: 1px dashed #8affbc;

  h1 {
    margin-top: -40px;
    font-weight: 900;
    font-size: 72px;
    color: #8affbc;
  }

  h2 {
    margin-top: 20px;
    text-align: center;
    max-width: 180px;
    font-size: 16px;
    color: #8affbc;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const TestifiesContainer = styled.div`
  display: flex;
  width: 1300px;
  padding: 40px 20px;

  margin-top: 150px;

  justify-content: space-between;
  align-items: flex-start;

  border-radius: 8px;

  background: url(${TestifyBackground});
  background-repeat: no-repeat;
  background-size: 1300px auto;
  background-position: 0 10px;

  @media (max-width: 800px) {
    width: 100vw;
    height: auto;
    flex-direction: column;
    align-items: center;
    background-color: rgba(245, 245, 245, 0.76);

    background-image: none;
    background-repeat: repeat;
    background-position: 0 0;

    margin-top: 20px;
  }
`;

export const SideTestify = styled.div`
  background: rgba(147, 229, 183, 0.36);
  backdrop-filter: blur(2px);

  height: 400px;
  width: 290px;

  padding: 15px 15px;

  border-radius: 8px;

  h1,
  h3 {
    color: #4c5153;
  }
  h1 {
    font-size: 16px;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    color: #3d3d3d;

    margin-top: 60px;
  }
  h3 {
    font-size: 14px;
  }

  img {
    border-radius: 50%;
    height: 110px;
    width: 110px;
    object-fit: cover;
  }
`;

export const ColCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 100vw;

  @media (max-width: 800px) {
    margin-top: 20px;
    h1 {
      margin: 0;
    }
  }
`;

export const MiddleTestifyContent = styled.div`
  margin-top: 58px;

  img:nth-child(1) {
    margin-bottom: -60px;
  }
  img:nth-child(3) {
    margin-left: 330px;
    margin-top: -45px;
  }

  @media (max-width: 800px) {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;

    h2 {
      max-width: 290px;
    }
    img:nth-child(1) {
      width: 30px;
      height: 30px;
      margin-bottom: -30px;
      margin-right: 10px;
    }
    img:nth-child(3) {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      margin-top: 110px;
    }
  }
`;

export const MiddleTestify = styled.div`
  width: 390px;

  h1 {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    font-size: 32px;
    color: #4c5153;
  }
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    color: #4c5153;
  }

  @media (max-width: 800px) {
    width: 85vw;
    margin-top: 30px;
    h1 {
      font-size: 26px;
    }
    h2 {
      text-align: center;
      width: 50vw;
      font-size: 14px;
    }
  }
`;

export const LGPDInnerContainer = styled.div`
  width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  h1 {
    font-weight: 700;
    font-size: 32px;
    color: #725cc0;
  }
  h2 {
    font-weight: 500;
    font-size: 24px;
    color: #725cc0;
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    width: 100vw;

    h1 {
      font-size: 26px;
    }
    h2 {
      text-align: center;
      font-size: 20px;
    }
    img {
      margin-bottom: 20px;
      width: 190px;
      height: auto;
    }
  }
`;

export const LGPDRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LGPDOutterContainer = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;

  margin-top: 50px;
  padding: 20px 0;

  background-image: url(${LGPDBackground});
  /* background-size: 1900px auto; */
  background-repeat: no-repeat;
  background-position: 0px 50px;

  @media (max-width: 800px) {
    width: 100vw;
  }
`;

export const WhatsAppButton = styled.a`
  position: fixed;
  right: 50px;
  bottom: 30px;

  @media (max-width: 500px) {
    right: 15px;
  }

  img {
  }
`;
