import React from "react";

import { Switch, Route } from "react-router-dom";

import Contact from "../pages/Contact";
import Main from "../pages/Main";
import Plans from "../pages/Plans";
import Therms from "../pages/Therms";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/funcionalidades" component={Main} />
      <Route path="/planos" component={Plans} />
      <Route path="/contato" component={Contact} />
      <Route path="/termos" component={Therms} />
    </Switch>
  );
}
