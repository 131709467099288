import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;

  position: fixed;
  top: 0px;

  padding: 0;

  background-color: white;
  box-shadow: 0px 0px 32px rgba(144, 164, 174, 0.32);

  a {
    text-decoration: none;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    height: auto;
  }
`;

export const InnerContainer = styled.div`
  width: 1300px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  img:nth-child(1) {
    height: 75px;
    margin-bottom: 5;
    margin-top: 5;
  }

  a {
    cursor: pointer;
  }

  @media (max-width: 800px) {
    width: 90%;
    padding-top: 10px;
    justify-content: space-between;
    img:nth-child(1) {
      height: 45px;
      margin-bottom: 10px;
      margin-top: 5px;
    }
  }
`;

export const TabsContainer = styled.div`
  margin-left: 140px;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Tab = styled.div`
  height: 85px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${(props) => (props.isActive ? "#8e7ad6" : "#7D8C94")};
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-top: 15px;
  }
  border-bottom: ${(props) =>
    props.isActive ? "2px solid #8e7ad6" : "2px #fff"};

  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  margin-left: 205px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const LoginButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #ccd1d3;
  border-radius: 4px;
  padding: 8px 0px;

  width: 158px;

  background: #f5f5f5;

  transition: 0.3s;

  h1 {
    color: #7d8c94;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

  &:hover {
    border-color: #7d8c94;
    background: #e8e9e9;
  }

  cursor: pointer;
`;

export const TestButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #8e7ad6;
  border-radius: 4px;
  padding: 8px 0px;
  margin-left: 15px;

  width: 230px;

  background: #8e7ad6;

  transition: 0.3s;

  h1 {
    color: #e8e3fc;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

  &:hover {
    border-color: #2b186c;
    background: #2b186c;
  }

  cursor: pointer;
`;

export const MobileContainer = styled.div`
  width: 100%;
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  padding-top: 10px;

  @media (min-width: 801px) {
    display: none;
  }
`;

export const MobileTab = styled.div`
  border: 1px solid;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 10px;
  width: 100%;

  cursor: pointer;

  border-color: #e8e9e9;

  h1 {
    font-weight: 500;
    font-size: 18px;
    color: ${(props) => (props.isActive ? "#8e7ad6" : "#7D8C94")};
  }
`;

export const MenuIcon = styled.img`
  display: none;
  width: 50px;

  cursor: pointer;

  @media (max-width: 800px) {
    display: block;
  }
`;
