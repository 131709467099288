import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: space-between;

  margin-top: 100px;

  background: #6f5ead;

  @media (max-width: 800px) {
    flex-direction: column;
    height: auto;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: flex-start;
  height: 90%;
  width: 200px;
  margin-top: 10px;
  margin-left: 50px;

  img {
    height: 75px;
  }

  @media (max-width: 800px) {
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;

export const ImagesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 200px;

  margin-right: 50px;

  @media (max-width: 800px) {
    display: none;
    height: auto;
  }
`;

export const CentralDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-weight: 500;
    font-size: 24px;

    color: #f5f5f5;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;

    color: #f5f5f5;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 50px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const MediaRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 40px;
    margin-right: 20px;
  }
  h2 {
    margin-right: 40px;
  }

  cursor: pointer;

  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;
