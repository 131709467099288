import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import Modal from "react-modal";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import API from "../services/api";

/**
 * import { ToastContainer, toast } from "react-toastify";
 * import "react-toastify/dist/ReactToastify.css";
 * import Utils from "../../src/utils";
 */

import "../css/all.css";
import "../css/aos.css";
import "../css/cookieconsent.min.css";
import "../css/magnific-popup.css";
import "../css/odometer-theme-minimal.css";
import "../css/prism-okaidia.css";
import "../css/simplebar.css";
import "../css/smart_wizard.css";
import "../css/smart_wizard_theme_arrows.css";
import "../css/smart_wizard_theme_circles.css";
import "../css/smart_wizard_theme_dots.css";
import "../css/swiper.css";
import "../css/theme.css";
import "../css/rtl.css";

import Logo from "../assets/Home/logoTerapiaInterativaNavBar.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 999,
  },
};

function ModalForm({ modalIsOpen, setIsOpen }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [submitButton, setSubmitButton] = useState("COMEÇAR");

  const checkPass = (password) => {
    if (password.length < 6) {
      return "A senha deve ter pelo menos 6 caracteres!";
    } else if (password.length > 50) {
      return "Senha muito longa!";
    } else if (password.search(/\d/) === -1) {
      return "Sua senha deve conter pelo menos 1 número!";
    } else if (password.search(/[a-zA-Z]/) === -1) {
      return "Sua senha deve conter pelo menos 1 letra!";
    }
    return "OK";
  };

  const submitAdd = (obj) => {
    return API.post(`/index/validar-cadastro-inicio`, obj);
  };

  const submitLogin = (obj) => {
    return API.post(`/index/dologin`, obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitButton("Processando");

    if (name === "" || email === "" || password === "") {
      toast.warn("Preencha todos os campos...", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitButton("COMEÇAR");
      return false;
    }
    let checkPassword = checkPass(password);
    if (checkPassword !== "OK") {
      toast.warn(checkPassword, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitButton("COMEÇAR");
      return false;
    }

    const obj = new URLSearchParams();
    obj.append("nome", name);
    obj.append("email", email);
    obj.append("senha", password);
    obj.append("lingua", 1);

    await submitAdd(obj)
      .then((resp) => {
        if (!resp.data.error) {
          toast.success(
            "Muito obrigado pelo cadastro! Aguarde enquanto você é redirecionado para o ambiente administrativo!",
            {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setTimeout(async () => {
            const obj2 = new URLSearchParams();
            obj2.append("fUsuario", email);
            obj2.append("fSenha", password);

            await submitLogin(obj2)
              .then((resp) => {
                if (resp.data.nome === "") {
                  if (resp.data.nao_logar) {
                    toast.error(
                      "Seu acesso está bloqueado. Entre em contato com o administrador da sua clínica.",
                      {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  } else {
                    toast.error("Dados Incorretos. Tente novamente.", {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                } else {
                  window.location.href = `https://app.terapiainterativa.com.br/gc/index/do-login-externo/?psico_user=${email}&psico_pass=${password}`;
                }
              })
              .catch((err) => {
                toast.error("Error Login, tente novamente mais tarde.", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }, 5000);
        }
      })
      .catch((err) => {
        toast.error("Error Cadastro, tente novamente mais tarde.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitButton("COMEÇAR");
      });
  };

  useEffect(() => {
    function load() {
      const { search } = document.location;
      if (search !== "" && search !== "?p=") {
        if (search.includes("?p=")) {
          console.log(`Parceiro: ${search.substring(3)}`);
          localStorage.setItem("@psicosite:partner", search.substring(3));
        }
      }
    }
    load();
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Assinar"
      >
        <div
          id="modal-dialog"
          style={isMobile ? {} : { width: 400 }}
          className="container mfp-with-anim popup-wrapper bg-contrast"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="box-logo-modal">
                <img
                  src={Logo}
                  className="img-responsive logo-modal"
                  alt="TerapiaInterativa"
                />
              </div>
              <p className="psico-modal-message">
                {"Teste a Terapia Interativa gratuitamente"}
              </p>
              <form className="form form-trial form-signup" name="form-signup">
                <div className="form-group mb-4">
                  <input
                    type="hidden"
                    name="url"
                    id="url-modal"
                    value={API.defaults.baseURL}
                  />
                  <input
                    type="text"
                    name="nome"
                    id="nome-modal"
                    maxLength={255}
                    className="form-control"
                    placeholder={"Nome"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-4">
                  <input
                    type="email"
                    name="email"
                    id="email-modal"
                    maxLength={255}
                    className="form-control"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    name="senha"
                    id="senha-modal"
                    maxLength={15}
                    className="form-control"
                    placeholder={"Senha"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group" style={{ textAlign: "center" }}>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-rounded btn-info mt-2"
                    disabled={submitButton === "Processando" ? true : false}
                  >
                    {submitButton}
                    {submitButton === "COMEÇAR" && (
                      <i className="fas fa-long-arrow-alt-right ml-2"></i>
                    )}
                    {submitButton === "Processando" && (
                      <i class="fas fa-spinner fa-spin ml-2"></i>
                    )}
                  </button>
                  <p className="small">{"Não requer cartão de crédito."}</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ModalForm;
