import React from "react";

import {
  Container,
  LogoDiv,
  CentralDiv,
  ImagesDiv,
  Row,
  MediaRow,
} from "./styles";

import { Link } from "react-router-dom";

import LogoLavanda from "../../assets/Footer/logoLavanda.svg";
import LeftHeart from "../../assets/Footer/leftHeart.svg";
import RightHeart from "../../assets/Footer/rightHeart.svg";
import FacebookLogo from "../../assets/Footer/facebookLogo.svg";
import TwitterLogo from "../../assets/Footer/twitterLogo.svg";
import InstagramLogo from "../../assets/Footer/instagramLogo.svg";

export default function Footer() {
  return (
    <Container>
      <LogoDiv>
        <img src={LogoLavanda} alt={LogoLavanda} />
      </LogoDiv>
      <CentralDiv>
        <h1>Siga nossas redes sociais</h1>

        <Row>
          <a
            href="https://www.facebook.com/terapiainterativa"
            target="_blank"
            rel="Facebook"
          >
            <MediaRow>
              <img src={FacebookLogo} alt={FacebookLogo} />

              <h2>Facebook</h2>
            </MediaRow>
          </a>
          <a
            href="https://www.instagram.com/terapiainterativaonline"
            target="_blank"
            rel="Instagram"
          >
            <MediaRow>
              <img src={InstagramLogo} alt={InstagramLogo} />

              <h2>Instagram</h2>
            </MediaRow>
          </a>
        </Row>
        <Link to="/termos">
          <h2
            style={{
              fontSize: 18,
              marginTop: 30,
              marginBottom: 10,
              fontWeight: 400,
              cursor: "pointer",
            }}
          >
            Termos de uso
          </h2>
        </Link>
      </CentralDiv>
      <ImagesDiv>
        <img src={RightHeart} alt={RightHeart} />
        <img src={LeftHeart} alt={LeftHeart} style={{ marginTop: 60 }} />
      </ImagesDiv>
    </Container>
  );
}
