import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *{
    outline:0;
    box-sizing:border-box;
  }

  a{
    text-decoration: none;
  }

  body{
    margin:0;
    padding:0;
    box-sizing:border-box;
    -webkit-font-smoothing:antialiased;
  }

  div{
    margin:0;
    padding: 0;
  }

  h1,h2,h3,h4,h5{
    font-family: "Roboto",sans-serif;
    margin: 0;
    padding:0;  
    font-weight: 400;
  }
  
`;
