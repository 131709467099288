import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  padding-top: 100px;
`;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: 1300px;

  padding: 20px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: "Poppins", sans-serif;

  font-weight: 700;
  font-size: 3rem;
  color: #001a3e;

  margin-bottom: 40px;
`;

export const SemiTitle = styled.h2`
  color: #6d768b;
  font-family: "Poppins", sans-serif;
  align-self: flex-start;

  font-weight: 700;
  font-size: 1rem;
  margin-top: 20px;
`;

export const Paragraph = styled.h3`
  color: #6d768b;
  font-family: "Poppins", sans-serif;

  font-weight: 400;
  font-size: 1rem;
  margin-top: 20px;
  align-self: flex-start;

  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    text-decoration: none;
    font-size: 1rem;
  }
`;
