import axios from "axios";

const API = axios.create({
  baseURL: "https://app.terapiainterativa.com.br/gc",
  headers: {
    Accept: "application/x-www-form-urlencoded; charset=UTF-8",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  },
});

API.interceptors.request.use(
  async (options) => {
    console.log(options);

    return options;
  },
  (error) => {
    return console.log(error);
  }
);

export default API;
