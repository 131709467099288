export const landingPageEvent = () =>{
  window.fbq('track','LandingPage');
}

export const contactPageEvent = () =>{
  window.fbq('track','ContactPage');
}

export const plansPageEvent = () =>{
  window.fbq('track','PlansPage');
}

export const thermsPageEvent = () =>{
  window.fbq('track','ThermsPage');
}