import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
`;

export const TitleContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 820px;

  margin-top: 200px;

  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 36px;
    color: #373f41;
  }
  h2 {
    font-size: 16px;
    color: #737b7d;
    max-width: 500px;
    margin-top: 15px;
  }

  @media (max-width: 800px) {
    max-width: none;
    padding: 0;
    text-align: center;
    width: 90%;
    align-items: center;
    margin-top: 100px;
  }
`;

export const PlansRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin-top: 50px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
`;

export const PlanDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: ${(props) => (props.active ? "1px solid #6f5ead" : "none")};

  width: 380px;
  max-width: 95vw;

  height: 900px;

  box-shadow: 0px 0px 32px rgba(144, 164, 174, 0.32);
  border-radius: 8px;

  padding: 26px;

  h1 {
    color: ${(props) => (props.active ? "#6f5ead" : "#677176")};
    font-size: 64px;
    font-weight: 700;
    position: relative;
  }
  h1:before {
    content: "R$";
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: -20px;
    font-weight: 400;
  }
  h1:after {
    content: "${(props) => props.value}";
    font-size: 14px;
    font-weight: 400;
  }

  h2 {
    font-weight: 500;
    color: ${(props) => (props.active ? "#6f5ead" : "#677176")};
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  span {
    font-size: 14px;
    display: block;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-bottom: 20px;
  }
`;

export const ItemDiv = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;

  display: flex;
  align-items: center;

  background: #f5f5f5;
  border-radius: 4px;

  h3 {
    color: ${(props) => (props.active ? "#6f5ead" : "#677176")};
    font-weight: 700;
    font-size: 14px;
    margin-left: 10px;
  }

  img {
    width: 16px;
    margin-left: 10px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TestButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: 100%;
  padding: 11px 0;

  border: ${(props) =>
    props.active ? "1px solid #6f5ead" : "1px solid #CCD1D3"};

  cursor: pointer;

  background: ${(props) => (props.active ? "#6f5ead" : "#F5F5F5")};
  border-radius: 4px;

  h3 {
    color: ${(props) => (props.active ? "#e8e3fc" : "#7D8C94")};
    font-size: 16px;
  }

  transition: 0.3s;

  &:hover {
    background: ${(props) => (props.active ? "#2b186c" : "#E8E9E9")};
    border: ${(props) =>
      props.active ? "1px solid #6f5ead" : "1px solid #7D8C94"};
  }
`;

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 100px;
  justify-content: center;
  align-items: center;

  background: #6f5ead;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
`;

export const InnerFAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1300px;
  margin: 40px 0px;

  h1 {
    font-family: "Mulish", sans-serif;
    color: #e8e3fc;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 28px;
  }

  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  width: ${(props) => (props.large ? "100%" : "49%")};

  background: #ffffff;
  border-radius: 8px;

  h1 {
    color: #4c5153;
    font-size: 14px;
    margin-bottom: 15px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 96%;

    margin-bottom: ${(props) => (props.first ? "25px" : "0px")};
  }
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  width: 100%;

  background: #f5f5f5;
  border-radius: 8px;

  h1 {
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #aeb9bf;
  }
  h2 {
    font-weight: 700;
    font-size: 14px;

    color: #4c5153;
  }
`;

export const Row = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;

  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 5px;
  }
`;

export const PlansValueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 25px;
`;

export const AnualValue = styled.div`
  background-color: ${(props) => (!props.plan ? `#E8E3FC` : ` #fff`)};
  border-color: #8e7ad6;
  box-shadow: none;
  color: ${(props) => (!props.plan ? ` #8E7AD6` : `#aeb9bf`)};
  border: 1px solid #8e7ad6;
  align-items: center;
  justify-content: center;
  border-radius: 44px 0 0 44px;
  width: 90px;
  padding: 10px;
  height: 44px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #2b186c;
    color: #fff;
  }
`;

export const MensalValue = styled.div`
  background-color: ${(props) => (props.plan ? `#E8E3FC` : ` #fff`)};
  border-color: #8e7ad6;
  box-shadow: none;
  color: ${(props) => (props.plan ? ` #8E7AD6` : `#aeb9bf`)};
  border: 1px solid #8e7ad6;
  align-items: center;
  justify-content: center;
  border-radius: 0 25px 25px 0;
  padding: 10px;
  padding: 10px;
  width: 90px;
  height: 44px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #2b186c;
    color: #fff;
  }
`;

export const WhatsAppButton = styled.a`
  position: fixed;
  right: 50px;
  bottom: 30px;

  @media (max-width: 500px) {
    right: 15px;
  }

  img {
  }
`;
