import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 150px 100px 0 100px;

  @media (max-width: 800px) {
    padding: 100px 10px 10px 10px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 1300px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
  }
`;

export const FormContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 0px 32px rgba(144, 164, 174, 0.32);
  border-radius: 8px;

  padding: 35px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  text-align: center;

  h1 {
    color: #373f41;
    font-size: 32px;
  }
  h2 {
    font-size: 16px;
    color: #737b7d;
    max-width: 380px;
    margin-top: 20px;
    margin-bottom: 60px;
  }
`;

export const FieldCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-size: 14px;
    color: #7d8c94;
  }
`;

export const InputField = styled.input`
  background: #f5f5f5;

  margin-top: 10px;
  margin-bottom: 28px;

  border: 1px solid #ccd1d3;
  box-sizing: border-box;
  border-radius: 2px;

  padding: 18px 16px;

  font-family: "Roboto", sans-serif;
`;

export const TextAreaField = styled.textarea`
  background: #f5f5f5;

  height: 200px;

  margin-top: 10px;
  margin-bottom: 28px;

  border: 1px solid #ccd1d3;
  box-sizing: border-box;
  border-radius: 2px;

  padding: 18px 16px;

  resize: none;

  font-family: "Roboto", sans-serif;
`;

export const SendButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  width: 200px;
  margin-top: 20px;

  background: #6f5ead;
  border-radius: 4px;

  cursor: pointer;

  transition: 0.3s;
  h1 {
    font-size: 16px;
    color: #e8e3fc;
  }

  &:hover {
    background: #2b186c;
  }
`;

export const ContactDataDiv = styled.div`
  display: flex;
  flex-direction: column;

  width: 500px;

  h1 {
    font-size: 32px;
    color: #373f41;
    margin-bottom: 30px;
  }
  h2 {
    color: #4c5153;
    font-size: 16px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    h1 {
      font-size: 24px;
      text-align: center;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  h2 {
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
`;

export const MediaButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 7px 0;
  margin-right: 10px;

  border: 1px solid #677176;
  box-sizing: border-box;
  border-radius: 28px;

  width: 200px;

  h1 {
    color: #4c5153;
    font-size: 24px;

    margin: 0;
  }

  cursor: pointer;

  @media (max-width: 800px) {
    margin-bottom: 25px;
  }
`;
