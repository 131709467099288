import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  TitleContainer,
  PlansRow,
  PlanDiv,
  ItemDiv,
  TestButton,
  ListContainer,
  FAQContainer,
  InnerFAQContainer,
  QuestionContainer,
  AnswerContainer,
  Row,
  PlansValueContainer,
  AnualValue,
  MensalValue,
  WhatsAppButton,
} from "./styles";

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

import PurpleCheck from "../../assets/Plans/purpleCheck.svg";
import GreyCheck from "../../assets/Plans/greyCheck.svg";

import WhatsAppImage from "../../assets/Home/whatsapp.png";

import ModalForm from "../../components/ModalForm";

import ReactGA from "react-ga";
import { plansPageEvent } from "../../facebookPixel";

export default function Plans(props) {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [plan, setPLan] = useState(false);

  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  useEffect(()=>{
    plansPageEvent()
  },[])

  return (
    <div
      style={{
        width: "100%",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <NavBar activePage={1} handleModal={handleModal} />
      <Container>
        <TitleContainer>
          <h1>Teste agora e se prepare para inovar na sua forma de atender</h1>
          <h2>
            Escolha o melhor plano para suas necessidades profissionais!
            Experimente sem compromisso por 15 dias
          </h2>
        </TitleContainer>
        <PlansValueContainer>
          <AnualValue onClick={() => setPLan(!plan)} plan={plan}>
            <span>Anual</span>
          </AnualValue>
          <MensalValue onClick={() => setPLan(!plan)} plan={plan}>
            <span>Mensal</span>
          </MensalValue>
        </PlansValueContainer>
        <PlansRow>
          <PlanDiv value={plan ? ",00" : ",20"} active={false}>
            <h1>{plan ? "59" : "47"}</h1>
            <span>{!plan ? "Por mês no plano anual" : "por mês"}</span>
            <h2>Plano básico</h2>
            <ListContainer>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Sala Virtual com 20 horas por mês</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Recursos Terapêuticos limitados</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Agenda</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Prontuário</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Gestão de Documentos</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Anamnese personalizável</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Notificações por e-mail e sms</h3>
              </ItemDiv>
            </ListContainer>

            <TestButton onClick={handleModal} active={false}>
              <h3>Testar gratuitamente</h3>
            </TestButton>
          </PlanDiv>
          <PlanDiv value={plan ? ",00" : ",20"} active={false}>
            <h1>{plan ? "99" : "79"}</h1>
            <span>{!plan ? "Por mês no plano anual" : "por mês"}</span>
            <h2>Plano intermediário</h2>
            <ListContainer>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Sala Virtual com 50 horas por mês</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Recursos Terapêuticos ilimitados</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Agenda</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Prontuário</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Gestão de Documentos</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Anamnese personalizável</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Notificações por e-mail e sms</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Finanças</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Aplicativo para pacientes</h3>
              </ItemDiv>
              <ItemDiv active={false}>
                <img src={GreyCheck} alt={GreyCheck} />
                <h3>Cadastro de secretária</h3>
              </ItemDiv>
            </ListContainer>

            <TestButton onClick={handleModal} active={false}>
              <h3>Testar gratuitamente</h3>
            </TestButton>
          </PlanDiv>
          <PlanDiv value={plan ? ",00" : ",20"} active={true}>
            <h1>{plan ? "129" : "103"}</h1>
            <span>{!plan ? "Por mês no plano anual" : "por mês"}</span>
            <h2>Plano avançado</h2>
            <ListContainer>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Sala Virtual ilimitada</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Recursos Terapêuticos ilimitados</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Agenda</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Prontuário</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Gestão de Documentos</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Anamnese personalizável </h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Notificações por e-mail e sms</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Finanças</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Aplicativo para pacientes</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>Cadastro de secretária</h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>
                  Perfil do profissional, busca de profissionais e agendamento
                  Online
                </h3>
              </ItemDiv>
              <ItemDiv active={true}>
                <img src={PurpleCheck} alt={PurpleCheck} />
                <h3>SMS ilimitado</h3>
              </ItemDiv>
            </ListContainer>
            <TestButton onClick={handleModal} active={true}>
              <h3>Testar gratuitamente</h3>
            </TestButton>
          </PlanDiv>
        </PlansRow>
        <FAQContainer>
          <InnerFAQContainer>
            <h1>Perguntas frequentes</h1>
            <QuestionContainer large={true}>
              <h1>
                Para onde irão os dados dos recursos terapêuticos usados na
                sessão?
              </h1>
              <AnswerContainer>
                <h1>Resposta</h1>
                <h2>
                  Os dados ficarão na tela da ficha do paciente em uma aba
                  exclusiva referente aos resultados destas aplicações dos
                  recursos e atividades.
                </h2>
              </AnswerContainer>
            </QuestionContainer>
            <Row>
              <QuestionContainer first={true} large={false}>
                <h1>
                  Qual a vantagem de assinar o anual em relação ao mensal?
                </h1>
                <AnswerContainer>
                  <h1>Resposta</h1>
                  <h2>
                    A grande diferença é que no período anual temos um desconto
                    especial para o plano, pagando somente 1 vez e será válido
                    para o ano todo. Caso utilize cartão de crédito, poderá ser
                    dividido em 12 vezes. Na cobrança mensal, todas as
                    assinaturas poderão ser realizadas utilizando cartão de
                    crédito ou boleto.
                  </h2>
                </AnswerContainer>
              </QuestionContainer>
              <QuestionContainer large={false}>
                <h1>
                  Para usar os recursos terapêuticos, obrigatoriamente, terei de
                  usar a sala virtual da plataforma?
                </h1>
                <AnswerContainer>
                  <h1>Resposta</h1>
                  <h2>
                    Não, em nosso sistema você encontrará um link para aplicar
                    os recursos terapêuticos sem a necessidade de utilizar a
                    nossa Sala Virtual. Os dados das aplicações são
                    automaticamente enviados para a ficha do paciente, mesmo sem
                    a utilização da sala virtual.
                  </h2>
                </AnswerContainer>
              </QuestionContainer>
            </Row>
            <Row>
              <QuestionContainer first={true} large={false}>
                <h1>
                  Posso cancelar minha assinatura da Terapia Interativa a
                  qualquer momento?
                </h1>
                <AnswerContainer>
                  <h1>Resposta</h1>
                  <h2>
                    Sim, basta enviar um e-mail para nosso suporte que
                    cancelaremos sua assinatura. Caso esteja pagando mensal não
                    há nenhum custo adicional. O cancelamento para o pagamento
                    anual está nas diretrizes do nosso termos de uso.
                  </h2>
                </AnswerContainer>
              </QuestionContainer>
              <QuestionContainer large={false}>
                <h1>Posso acessar e utilizar o sistema pelo smartphone?</h1>
                <AnswerContainer>
                  <h1>Resposta</h1>
                  <h2>
                    Sim, nosso sistema pode ser acessado pelo navegador do seu
                    computador e do seu smartphone. Além disso, em breve
                    lançaremos um aplicativo para facilitar ainda mais a
                    utilização mobile.
                  </h2>
                </AnswerContainer>
              </QuestionContainer>
            </Row>
          </InnerFAQContainer>
        </FAQContainer>
      </Container>
      <Footer />
      <ModalForm modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <WhatsAppButton
        href=" https://api.whatsapp.com/send/?phone=558181119190&text&app_absent=0"
        target="_blank"
      >
        <img src={WhatsAppImage} alt={WhatsAppImage} />
      </WhatsAppButton>
    </div>
  );
}
